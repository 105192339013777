import { getAuthToken } from 'xo/auth-utils';
import { ApiCurrentUserFragment } from 'xo/graphql/api/current-user-fragment.generated';
import { ApiSettingsSiteFragment } from 'xo/graphql/api/settings-site-fragment.generated';
import { createStore } from 'zustand';
import { createStoreProvider } from '../store/store-factory';
import {
  hasApiTransportModuleAccess,
  hasApiVisitorModuleAccess,
} from './permissions';

export enum AuthStatus {
  Loading = 1,
  Authenticated = 2,
  Unauthenticated = 3,
}

export interface UserStoreState {
  token: string | null;
  setToken: (token: string | null) => void;
  refreshToken: () => Promise<void>;
  authStatus: AuthStatus;
  setAuthStatus: (authStatus: AuthStatus) => void;
  // the current user whether assumed by an XO admin or not
  currentUser: ApiCurrentUserFragment | null;
  setCurrentUser: (currentUser: ApiCurrentUserFragment | null) => void;
  // the underlying user, regardless of assumption. ie. if we're an admin assuming another user, this is the admin user object
  actualCurrentUser: ApiCurrentUserFragment | null;
  setActualCurrentUser: (
    actualCurrentUser: ApiCurrentUserFragment | null,
  ) => void;
  refetchCurrentUser: () => Promise<void>;
  setRefetchCurrentUser: (refetchCurrentUser: () => Promise<void>) => void;
  sites: ApiSettingsSiteFragment[];
  visitorSites: ApiSettingsSiteFragment[];
  transportSites: ApiSettingsSiteFragment[];
  setSites: (sites: ApiSettingsSiteFragment[]) => void;
  clear: () => void;
}

export const createUserStore = () =>
  createStore<UserStoreState>((set, get) => ({
    token: null,
    setToken: token => set({ token }),
    refreshToken: async () => {
      const currentToken = await getAuthToken();
      if (currentToken !== get().token) {
        get().clear();
      }
    },
    authStatus: AuthStatus.Loading,
    setAuthStatus: authStatus => set({ authStatus }),
    currentUser: null,
    setCurrentUser: currentUser => set({ currentUser }),
    actualCurrentUser: null,
    setActualCurrentUser: actualCurrentUser => set({ actualCurrentUser }),
    refetchCurrentUser: Promise.resolve,
    setRefetchCurrentUser: refetchCurrentUser => set({ refetchCurrentUser }),
    sites: [],
    visitorSites: [],
    transportSites: [],
    setSites: sites =>
      set({
        sites,
        visitorSites: sites.filter(hasApiVisitorModuleAccess),
        transportSites: sites.filter(hasApiTransportModuleAccess),
      }),
    clear: () =>
      set({
        token: null,
        authStatus: AuthStatus.Loading,
        currentUser: null,
        actualCurrentUser: null,
        sites: [],
        visitorSites: [],
        transportSites: [],
      }),
  }));

type UserStore = ReturnType<typeof createUserStore>;

export const { Provider: UserStoreProvider, useStore: useUserStore } =
  createStoreProvider<UserStoreState, UserStore>(createUserStore);
