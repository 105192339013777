import { getTimeZones } from '@vvo/tzdb';
import { Dayjs } from 'dayjs';

const timeZones = getTimeZones();
const sydney = timeZones.find(tz => tz.name === 'Australia/Sydney')!;

// these don't exist in the dataset, so add them and reference the syd abbreviation
const extraAestTimeZoneNames = [
  'Australia/Canberra',
  'Australia/Hobart',
  'Australia/Melbourne',
];

export const timeZoneAbbrMap: Record<string, string> = Object.fromEntries(
  timeZones
    .map(tz => [tz.name, tz.abbreviation])
    .concat(extraAestTimeZoneNames.map(tz => [tz, sydney.abbreviation])),
);

export const timeZoneCountryCodeMap: Record<string, string> =
  Object.fromEntries(
    timeZones
      .map(tz => [tz.name, tz.countryCode])
      .concat(extraAestTimeZoneNames.map(tz => [tz, sydney.countryCode]))
      .filter(([_, c]) => !!c),
  );

export const timezoneOptions = timeZones
  .map(t => t.name)
  .concat(extraAestTimeZoneNames)
  .sort()
  .map(name => ({
    key: name,
    label: name,
    value: name,
  }));

export const formatTimezone = ({
  timezone,
  dateTz,
}: {
  timezone: string;
  dateTz?: Dayjs;
}) =>
  `Timezone: ${formatTimezoneAbbr(timezone)}${
    dateTz ? ` (GMT${dateTz.format('Z')})` : ''
  }`;

export const formatTimezoneAbbr = (timezone: string) =>
  timeZoneAbbrMap[timezone] ?? timezone;
