import { usePrevious } from '@mantine/hooks';
import {
  Form as AntForm,
  FormItemProps as AntFormItemProps,
  FormProps as AntFormProps,
  FormInstance,
} from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { ShouldUpdate } from 'rc-field-form/lib/Field';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Box } from 'xo/core';
import { useDebouncedCallback } from 'xo/hooks/component-hooks';
import { margin } from 'xo/styles/tailwind-theme';
import { useScrollToFirstError } from '../../hooks/form-hooks';
import { HelpIconButton } from '../components/help-icon-button';
import { LocalStorage } from '../shared/local-storage';
import { requiredValidator } from './validators';

export interface FormProps<T> extends Omit<AntFormProps, 'form' | 'children'> {
  children?: ((form: FormInstance<T>) => React.ReactNode) | React.ReactNode;
  // Whether to automatically trigger updates when setting form.setFieldsValue or form.setFieldValue
  // Note this will only update children of the Form component
  shouldUpdate?: ShouldUpdate<T>;
  // How to persist form changes to local storage
  persist?: { localStorageKey: string; onLoad?: (values?: T) => T | undefined };
  form: FormInstance<T>;
}

export interface FormItemProps extends AntFormItemProps {
  labelRight?: React.ReactNode;
}

export const FormItem = ({ labelRight, tooltip, ...rest }: FormItemProps) => {
  const formItem = (
    <AntForm.Item
      {...rest}
      tooltip={
        typeof tooltip === 'string'
          ? {
              title: tooltip,
              placement: 'top',
              trigger: ['click', 'hover'],
              icon: (
                <HelpIconButton
                  style={{ marginTop: `-${margin[1]}`, marginLeft: margin[2] }}
                />
              ),
              overlayInnerStyle: { textAlign: 'center' },
            }
          : tooltip
      }
    />
  );

  return labelRight ? (
    <Box position="relative">
      {formItem}
      <Box position="absolute" right={0} top={0}>
        {labelRight}
      </Box>
    </Box>
  ) : (
    formItem
  );
};

export const FormUpdater = <T extends {}>({
  shouldUpdate = true,
  children,
}: {
  children: (form: FormInstance<T>) => React.ReactNode;
  shouldUpdate?: ShouldUpdate<T>;
}) => (
  <FormItem shouldUpdate={shouldUpdate} noStyle>
    {form => children(form as FormInstance<T>)}
  </FormItem>
);

export const formItemProps = ({
  name,
  label,
  required = true,
}: {
  name: NamePath;
  label: string;
  required?: boolean;
}): FormItemProps => ({
  label,
  name,
  required,
  rules: required ? [requiredValidator(label)] : undefined,
});

export const Form = <T extends {}>({
  form,
  children,
  persist,
  shouldUpdate = true,
  initialValues: defaultInitialValues,
  ...rest
}: FormProps<T>) => {
  const onScrollToError = useScrollToFirstError(form);

  const renderChildren = shouldUpdate ? (
    <AntForm.Item noStyle shouldUpdate={shouldUpdate}>
      {children as React.ReactNode}
    </AntForm.Item>
  ) : (
    children
  );

  const prevPersist = usePrevious(persist);
  useEffect(() => {
    if (prevPersist && !persist) {
      LocalStorage.removeItem(prevPersist.localStorageKey);
    }

    if (!prevPersist && persist && form) {
      LocalStorage.setItem(persist.localStorageKey, form.getFieldsValue(true));
    }
  }, [persist, prevPersist, form]);

  const onLoadLocalStorage = useCallback(() => {
    if (persist) {
      const data = LocalStorage.getItem(persist.localStorageKey) as
        | T
        | undefined;
      const loadedData = persist.onLoad ? persist.onLoad(data) : data;
      return loadedData &&
        (Object.keys(loadedData).length > 0 ||
          (Array.isArray(loadedData) && loadedData.length > 0))
        ? loadedData
        : undefined;
    }
  }, [persist]);

  const initialValues = useMemo(
    () =>
      persist?.localStorageKey
        ? onLoadLocalStorage() ?? defaultInitialValues
        : defaultInitialValues,
    [persist?.localStorageKey, defaultInitialValues, onLoadLocalStorage],
  );

  const renderForm = (
    <AntForm
      form={form}
      onFinishFailed={onScrollToError}
      preserve={true}
      initialValues={initialValues}
      layout={rest.layout ?? 'vertical'}
      // We use only Ant's in-JS validation, and disable the native browser validation. Using the
      // browser validation leads to confusing behaviour particularly with the PhoneOrEmailInput
      // (see https://exoflare.atlassian.net/browse/KX-1591?focusedCommentId=32388 )
      noValidate
      {...rest}
    >
      {renderChildren as React.ReactNode}
    </AntForm>
  );

  const onFormChange = useDebouncedCallback(
    () => {
      if (persist && form) {
        LocalStorage.setItem(
          persist.localStorageKey,
          form.getFieldsValue(true),
        );
      }
    },
    500,
    [form],
  );

  return (
    <AntForm.Provider onFormChange={onFormChange}>
      {renderForm}
    </AntForm.Provider>
  );
};

export const FormList = AntForm.List;
