import { useWindowEvent } from '@mantine/hooks';
import { useCallback } from 'react';
import { useUserStore } from './user-store';

const useRefreshTokenOnFocus = (
  { disabled }: { disabled: boolean } = { disabled: false },
) => {
  const refreshToken = useUserStore(state => state.refreshToken);
  useWindowEvent(
    'focus',
    useCallback(() => {
      if (!disabled) {
        refreshToken();
      }
    }, [refreshToken, disabled]),
  );
};

export default useRefreshTokenOnFocus;
