import { AppHeader } from './app-header';
import { NavBarProps } from './host-portal-navbar';

interface KioskNavBarProps extends NavBarProps {}

export const KioskNavBar = (props: KioskNavBarProps) => {
  return (
    <AppHeader
      {...props}
      onClick={props.onBack}
      back={!!(props.onBack || props.backTo)}
    />
  );
};
