import axios, { AxiosError, AxiosHeaders } from 'axios';
import { XO_AUTHORISATION_HEADER } from 'xo/constants';
import { emitSignOut } from 'xo/login/use-signout-listener';
import { ErrorResponseType } from 'xo/rest-api';
import { isOfflineError } from 'xo/utils/offline-utils';
import { errorPageTypes } from '../app/components/error-page';
import { ErrorPageConfig } from '../app/components/error-page-provider';
import {
  onNotifyOfflineError,
  onNotifyServerError,
} from '../app/notifications';
import { getRequestHeaders } from './auth-utils';

export interface ApiOptions {
  notifyOnError?: boolean;
  onErrorPage?: (config: ErrorPageConfig) => Promise<void>;
  open?: boolean;
}

export type ApiResource =
  | 'visit'
  | 'form'
  | 'user'
  | 'dashboard'
  | 'site'
  | 'schedule'
  | 'delivery'
  | 'delivery-people'
  | 'admin'
  | 'organisation'
  | 'visit-series'
  | 'sign-up'
  | 'validation';

export const getApi = (
  resource: ApiResource,
  options: ApiOptions = { notifyOnError: true },
) => {
  const version = options.open || resource === 'form' ? 'v3-open' : 'v3';
  const instance = axios.create({
    baseURL: `/api/${version}/${resource}`,
  });

  instance.interceptors.request.use(async config => {
    const headers = await getRequestHeaders(config.headers);
    config.headers = new AxiosHeaders(headers);

    if (config.headers[XO_AUTHORISATION_HEADER]) {
      const version = config.baseURL?.split('/')[2];
      if (resource === 'form' && version === 'v3-open') {
        config.baseURL = config.baseURL?.replace('v3-open', 'v3');
      }
    }

    return config;
  });

  instance.interceptors.response.use(
    config => config,
    async (
      error: AxiosError<{ message: string; type?: ErrorResponseType }>,
    ) => {
      console.error(error);

      // indicates the frontend and backend versions don't match, so we should reload
      if (error.response?.data?.type === 'COMMIT_MISMATCH') {
        window.location.reload();
      } else if (isOfflineError(error as any)) {
        onNotifyOfflineError();
      } else if (
        error.response?.status &&
        error.response.status?.toString()[0] === '5'
      ) {
        // Always show the ultimate fail notification if it's a 500 error
        onNotifyServerError();
      } else if (error.response?.data?.type === 'NOTIFICATION') {
        onNotifyServerError(error.response.data.message);
      } else if (error.response?.status === 401) {
        emitSignOut();
      } else if (error.response?.data?.type && options.onErrorPage) {
        // FIXME Make onErrorPage available in every API call
        options.onErrorPage({
          path: window.location.pathname,
          props: errorPageTypes[error.response.data.type]!,
        });
      } else if (options.notifyOnError) {
        onNotifyServerError();
      }

      throw error;
    },
  );

  return instance;
};
