import { Location } from 'history';
import { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { signOut } from 'xo/auth-utils';
import { useResetClient } from 'xo/graphql/urql-provider';
import { useIsLoggedIn } from 'xo/login/auth-status';
import { useSignOutListener } from 'xo/login/use-signout-listener';
import { AuthStatus, useUserStore } from 'xo/login/user-store';
import { appRoutes } from 'xo/navigation/web-routes';
import {
  WebViewEventType,
  postWebViewMessage,
} from 'xo/webview/webview-events';
import { shallow } from 'zustand/shallow';
import { ErrorPageContext } from '../app/components/error-page-provider';
import { LocalStorage } from '../app/shared/local-storage';
import { resetAnalytics } from './analytics';

export interface LoginRedirectState {
  redirect: Location;
}

export const useOnSignOut = () => {
  const history = useHistory();
  const { errorPages } = useContext(ErrorPageContext);
  const resetClient = useResetClient({
    clear: () => Promise.resolve(),
  });
  const [setAuthStatus, clearUserStore] = useUserStore(
    state => [state.setAuthStatus, state.clear],
    shallow,
  );

  const onSignOut = useCallback(
    async ({ redirect }: { redirect?: string | false } = {}) => {
      setAuthStatus(AuthStatus.Unauthenticated);
      await signOut();
      resetAnalytics();
      errorPages.clear();
      LocalStorage.removeItem(LocalStorage.getAssumedUserKey());

      // Reset in a timeout so queries don't get retriggered before we've navgiated
      // otherwise we get 401's from the API due to the auth token missing
      setTimeout(() => {
        resetClient();
        clearUserStore();
      });

      // if running in mobile webview, allow the native app to sign out too
      postWebViewMessage(WebViewEventType.SignOut);

      if (redirect) {
        history.push(redirect ?? appRoutes.account.login);
      }
    },
    [history, errorPages, resetClient, setAuthStatus, clearUserStore],
  );

  useSignOutListener(onSignOut);

  return onSignOut;
};

export const useIsLoggedInRedirect = () => {
  const isLoggedIn = useIsLoggedIn();
  const history = useHistory<LoginRedirectState>();
  useEffect(() => {
    if (!isLoggedIn) {
      history.push({
        pathname: appRoutes.account.login,
        state: { redirect: history.location },
      });
    }
  }, [isLoggedIn, history]);

  return isLoggedIn;
};
