import { sortBy } from 'lodash';
import { ApiConsignmentNoteDocumentKind } from 'xo/graphql/api/enums/consignment-note-document-kind.generated';
import { ApiSiteKind } from 'xo/graphql/api/enums/site-kind.generated';
import { ApiSupportedPhoneRegion } from 'xo/graphql/api/enums/supported-phone-region.generated';
import { createEnumOptions } from './utils/option-utils';

export const ISO_8601_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export const XO_ADMIN_ASSUME_USER_HEADER = 'XO-Admin-Assume-User';
export const XO_CLIENT_COMMIT_HEADER = 'XO-Client-Commit';
export const XO_AUTHORISATION_HEADER = 'XOAuthorization';
export const XO_CLIENT_CORRELATION_ID = 'XO-Client-Correlation-Id';

export const CLIENT_ID_STORAGE_KEY = 'CLIENT_ID';

export const SUPPORT_PHONE = '+61 406 304 616';
export const SUPPORT_EMAIL = 'support@exoflare.io';
export const USER_GUIDE_URL =
  'https://www.notion.so/ExoFlare-user-guide-fe8146ebfda94e10905736c9f38ba1c9';
export const MARKETING_TRANSPORT_LINK = 'https://www.exoflare.io/transport';
export const TERMS_OF_USE_LINK = 'https://www.exoflare.io/terms-of-use';
export const PRIVACY_POLICY_LINK = 'https://www.exoflare.io/privacy-policy';

export const SERVER_ERROR =
  'There was an unexpected error. Please try again later.';

export const TODAY_LABEL = 'Today';

export const siteKindNameMap: Record<ApiSiteKind, string> = {
  [ApiSiteKind.Farm]: 'Farm - pig',
  [ApiSiteKind.FarmGeneral]: 'Producer',
  [ApiSiteKind.FarmChicken]: 'Chicken broiler',
  [ApiSiteKind.FarmChickenBreeder]: 'Chicken production breeder',
  [ApiSiteKind.FarmChickenBreederElite]: 'Chicken elite breeder',
  [ApiSiteKind.FarmChickenEggsBreeder]: 'Layer breeder',
  [ApiSiteKind.FarmChickenEggsGradingFloor]: 'Layer grading floor',
  [ApiSiteKind.FarmChickenEggsLaying]: 'Layer production',
  [ApiSiteKind.FarmChickenEggsRearing]: 'Layer rearing',
  [ApiSiteKind.FarmChickenRearing]: 'Chicken rearing breeder',
  [ApiSiteKind.FarmChickenHatchery]: 'Chicken hatchery (for broiler)',
  [ApiSiteKind.FarmChickenHatcheryBreeder]: 'Chicken hatchery (for breeder)',
  [ApiSiteKind.FeedMill]: 'Feed mill - pig',
  [ApiSiteKind.Abattoir]: 'Abattoir',
  [ApiSiteKind.Feedlot]: 'Feedlot',
  [ApiSiteKind.FeedMillGeneral]: 'Feed mill - general',
  [ApiSiteKind.ProcessorChicken]: 'Chicken processor',
  [ApiSiteKind.TransportDepot]: 'Transport depot',
  [ApiSiteKind.Other]: 'Other',
  [ApiSiteKind.FarmDairy]: 'Dairy farm',
  [ApiSiteKind.FarmDuckHatchery]: 'Duck meat hatchery',
  [ApiSiteKind.FarmDuckBroiler]: 'Duck meat broiler',
  [ApiSiteKind.FarmDuckBreeder]: 'Duck meat breeder',
  [ApiSiteKind.ProcessorDuck]: 'Duck processor',
};

export const siteKindOptions: {
  key: string;
  label: string;
  value: ApiSiteKind;
}[] = sortBy(Object.entries(siteKindNameMap), ([, name]) => name).map(
  ([value, label]) => ({
    key: value, // for compatibility with ControlledSelect
    value: value as ApiSiteKind,
    label: label as string,
  }),
);

// From https://www.figma.com/file/nDyctzixpUQAJrkMduNAgb/Visitor-for-Poultry-Testing-Mockups?node-id=1132%3A44019&t=l3xMdbTsCBuvqbkC-0
const siteKindDisplayOrder: ApiSiteKind[] = [
  ApiSiteKind.FarmChickenBreederElite,
  ApiSiteKind.FarmChickenHatcheryBreeder,
  ApiSiteKind.FarmChickenRearing,
  ApiSiteKind.FarmChickenBreeder,
  ApiSiteKind.FarmChickenHatchery,
  ApiSiteKind.FarmChicken,
  ApiSiteKind.ProcessorChicken,
  ApiSiteKind.FarmChickenEggsBreeder,
  ApiSiteKind.FarmChickenEggsRearing,
  ApiSiteKind.FarmChickenEggsLaying,
  ApiSiteKind.FarmChickenEggsGradingFloor,
  ApiSiteKind.FarmDuckBreeder,
  ApiSiteKind.FarmDuckHatchery,
  ApiSiteKind.FarmDuckBroiler,
  ApiSiteKind.ProcessorDuck,
  ApiSiteKind.FarmDairy,
  ApiSiteKind.FarmGeneral,
  ApiSiteKind.Feedlot,
  ApiSiteKind.FeedMillGeneral,
  ApiSiteKind.FeedMill,
  ApiSiteKind.Farm,
  ApiSiteKind.Abattoir,
  ApiSiteKind.TransportDepot,
  ApiSiteKind.Other,
];
export const siteKindDisplayOrderMap: Record<ApiSiteKind, number> =
  Object.fromEntries(siteKindDisplayOrder.map((k, i) => [k, i])) as Record<
    ApiSiteKind,
    number
  >;

export enum OrgFeatureFlag {
  ArrivalDashboard = 'ARRIVAL_DASHBOARD',
  BaseAddressBook = 'BASE_ADDRESS_BOOK',
  ConsignmentNotes = 'CONSIGNMENT_NOTES',
  DeliveryPrebooking = 'DELIVERY_PREBOOKING',
  EmployeeSignIn = 'EMPLOYEE_SIGN_IN',
  TransportScheduling = 'TRANSPORT_SCHEDULING',
  VisitorPrebooking = 'VISITOR_PREBOOKING',
  VisitorRequireLowRiskReview = 'VISITOR_REQUIRE_LOW_RISK_REVIEW',
  VisitorVisitsRemainOpenAfterFutureSignIn = 'VISITOR_VISITS_REMAIN_OPEN_AFTER_FUTURE_SIGN_IN',
  SiteAdditionalRequiredActions = 'SITE_ADDITIONAL_REQUIRED_ACTIONS',
  SmartSign = 'SMART_SIGN',
}

export enum UserFeatureFlag {
  EditQuarantine = 'EDIT_QUARANTINE',
  SilenceConNoteNotifications = 'SILENCE_CON_NOTE_NOTIFICATIONS',
  // `NATIVE_APP` was previously used to enable the mobile app, but has since been deprecated. until the DB is cleaned up, we should avoid using the same flag again
}

export const { options: documentTypeOptions, getLabel: getDocumentTypeLabel } =
  createEnumOptions(
    ApiConsignmentNoteDocumentKind,
    {
      [ApiConsignmentNoteDocumentKind.BrandOrEarmark]: {
        label: 'Brand / Earmark',
      },
      [ApiConsignmentNoteDocumentKind.MsaCertificate]: {
        label: 'MSA certificate',
      },
      [ApiConsignmentNoteDocumentKind.NfasDeliveryDocket]: {
        label: 'NFAS delivery docket',
      },
      [ApiConsignmentNoteDocumentKind.DownOrDoa]: {
        label: 'Photo of Down / DOA',
      },
      [ApiConsignmentNoteDocumentKind.NvdEuvdVendorDeclaration]: {
        label: 'NVD/EUVD (Vendor Declaration)',
      },
    },
    o => (o.value === ApiConsignmentNoteDocumentKind.Other ? 1 : 2),
  );

export const TIME_FORMAT = 'h:mmA';

// proxy API calls through charles to see traffic
export const CHARLES_LOCALHOST = `http://localhost.charlesproxy.com:8000`;

// from backend/src/visitor_tools/permissions.py
export enum UserRole {
  OrgAdmin = 'org-admin',
  OrgGatehouse = 'org-gatehouse',
  OrgLairage = 'org-lairage',
  OrgPlanner = 'org-scheduler',
}

// dummy payload with an expiry set far into the future
export const mockJwtToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyLCJleHAiOjMyNTAzNjQwNDAwfQ.RVMRhwOtKdvfY_xtfWn4-mOdEtt0MEI4EddOf-iXS88';

export const phoneInfoForPhoneRegion: Record<
  ApiSupportedPhoneRegion,
  {
    minLength: number;
    countryCode: string;
    smsOptOutEnabled?: boolean;
  }
> = {
  // +679 400 000 (can have 6 or 7 digits)
  [ApiSupportedPhoneRegion.Fj]: { minLength: 10, countryCode: '+679' },
  // +61 400 000 000
  [ApiSupportedPhoneRegion.Au]: { minLength: 12, countryCode: '+61' },
  // +64 3 234 5678
  [ApiSupportedPhoneRegion.Nz]: { minLength: 11, countryCode: '+64' },
  // +63 2 8123 4567
  [ApiSupportedPhoneRegion.Ph]: { minLength: 12, countryCode: '+63' },
  // +1 201-555-0123
  [ApiSupportedPhoneRegion.Us]: {
    minLength: 12,
    countryCode: '+1',
    smsOptOutEnabled: true,
  },
};
