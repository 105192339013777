import { usePrevious } from '@mantine/hooks';
import {
  Table as AntTable,
  TableColumnProps as AntTableColumnProps,
  TableProps as AntTableProps,
  TablePaginationConfig,
} from 'antd';
import { SortOrder, SorterResult } from 'antd/es/table/interface';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import type { Path } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { Button } from 'xo/components/button';
import { Box, HStack } from 'xo/core';
import { ApiSortDirection } from 'xo/graphql/api/enums/sort-direction.generated';
import { SvgSearch } from 'xo/svg/svg-search';
import { useQueryParamState } from '../../hooks/route-hooks';
import { useIsXoAdmin } from '../../hooks/shared-hooks';
import { Input } from '../forms/input';
import { capitalize } from '../utils';
import { downloadCsv } from '../utils/csv-utils';
import { usePaginationQueryParams } from './pagination-hooks';
import { useFuzzySearchTableRows } from './table-hooks';
import { PaginatedTableView, getSearchTooltip } from './table-utils';
import './table.overrides.css';

export const getStringSorter = <T extends {}>(
  field: (e: T) => string | undefined,
) => ({
  compare: (a: T, b: T) =>
    String(field(a) ?? '').localeCompare(String(field(b) ?? '')),
});

export const getNumberSorter = <T extends {}>(
  field: (e: T) => number | undefined,
) => ({
  compare: (a: T, b: T) => (field(a) || 0) - (field(b) || 0),
});

const sortDates = (
  undefinedPosition: 'smallest' | 'largest',
  a?: Dayjs,
  b?: Dayjs,
) => {
  if (!a && !b) return 0;
  if (!a && b) return undefinedPosition === 'largest' ? 1 : -1;
  if (!b && a) return undefinedPosition === 'largest' ? -1 : 1;
  return a!.isAfter(b!) ? 1 : -1;
};

export const getDateSorter = <T extends {}>(
  field: keyof T | ((e: T) => Dayjs | undefined),
  opts?: { undefinedPosition?: 'smallest' | 'largest' },
) => {
  const lookup =
    typeof field === 'function' ? field : (e: T) => e[field] as any;
  return {
    compare: (a: T, b: T) =>
      sortDates(opts?.undefinedPosition ?? 'largest', lookup(a), lookup(b)),
  };
};

export const Column = AntTable.Column;
export type TableColumnProps<
  T,
  TSortColumnEnum = any,
> = AntTableColumnProps<T> & {
  suppressClick?: boolean;
  admin?: boolean;
  hideOnPrint?: boolean;
  // true defaults to using path specified by dataIndex
  search?: true | Path<T> | Path<T>[];
  searchTitle?: string;
  // for API paginated tables, provide this enum to enable sorting on this column
  apiSort?: TSortColumnEnum;
};

export interface TableCsvExportProps<T> {
  filename: string;
  transformer: (data: T[]) => any[][];
  headers: string[];
}

export enum RowHighlightType {
  Selected = 'xo-selected-row',
  Generic = 'xo-table-highlighted-row',
}

const getApiSortDirection = (
  sort: SortOrder | ApiSortDirection | undefined,
  defaultSortDirection: ApiSortDirection = ApiSortDirection.Descending,
): ApiSortDirection =>
  !sort
    ? defaultSortDirection
    : sort === 'ascend'
      ? ApiSortDirection.Ascending
      : sort === 'descend'
        ? ApiSortDirection.Descending
        : sort;

const toSortOrder = (sortDirection: ApiSortDirection) =>
  sortDirection === ApiSortDirection.Ascending ? 'ascend' : 'descend';

export interface TableProps<T, TSortColumnEnum = any> extends AntTableProps<T> {
  striped?: boolean;
  columns: TableColumnProps<T>[];
  queryParamPrefix?: boolean;
  rowRoute?: (item: T) => string;
  rowRouteIncludeSearch?: boolean;
  overflowX?: boolean;
  // the GraphQL API defines a column enum which we could use as the generic type here, but not all usages of the table will have one yet so use keyof to maintain compatibility for now
  defaultSortField?: keyof T;
  // similarly, the API now defines a sort direction enum, but not all usages of the table will have one yet so use preexisting type for backwards compatibility
  defaultSortDirection?: ApiSortDirection;
  allowOverflow?: boolean;
  exportCsv?: TableCsvExportProps<T> | TableCsvExportProps<T>[];
  headerRight?: React.ReactNode;
  headerLeft?: React.ReactNode;
  rowClassName?: (item: T) => RowHighlightType | string;
  // onViewChange will only be called if at least 1 column has apiSort defined
  onViewChange?: (data: PaginatedTableView<TSortColumnEnum>) => void;
  // for API paginated tables, provide the total number of records for pagination to display correctly
  totalRecords?: number;
}

export const Table = <T extends object, TSortColumnEnum = any>({
  striped = true,
  columns,
  queryParamPrefix,
  rowRoute,
  rowRouteIncludeSearch,
  className,
  defaultSortField = 'date' as keyof T | undefined,
  defaultSortDirection = ApiSortDirection.Descending,
  allowOverflow,
  exportCsv,
  dataSource,
  headerRight,
  headerLeft,
  onViewChange,
  totalRecords,
  ...rest
}: TableProps<T, TSortColumnEnum>) => {
  const localSortColumns = columns.filter(column => column.sorter);
  const apiSortColumns = columns.filter(column => column.apiSort);
  if (localSortColumns.length > 1 && apiSortColumns.length > 1) {
    throw new Error(
      'Table cannot define columns with mixture of local `sorter` and `apiSort`. Choose one or the other.',
    );
  }

  const isXoAdmin = useIsXoAdmin();

  const getParamName = useCallback(
    (name: string) =>
      `${queryParamPrefix ?? ''}${queryParamPrefix ? capitalize(name) : name}`,
    [queryParamPrefix],
  );

  const [sortField, setSortField] = useQueryParamState<keyof T>(
    getParamName('sortField'),
    defaultSortField,
  );
  const [sortDirection, setSortDirection] =
    useQueryParamState<ApiSortDirection>(
      getParamName('sortDirection'),
      defaultSortDirection ?? ApiSortDirection.Descending,
    );

  // backwards compatibility in case users had bookmarked a table view with the old sort direction keys
  useEffect(() => {
    const sort = getApiSortDirection(sortDirection, defaultSortDirection);
    if (sort !== sortDirection) {
      setSortDirection(sort);
    }
  }, [sortDirection, setSortDirection, defaultSortDirection]);

  const onViewChangeCb = useCallback(
    (
      page: number,
      pageSize: number,
      sortField: keyof T | undefined,
      sortDirection: SortOrder | ApiSortDirection | undefined,
    ) => {
      let sortColumn =
        columns.find(
          column =>
            isEqual(String(column.dataIndex), sortField) && column.apiSort,
        ) ??
        columns.find(
          column =>
            isEqual(String(column.dataIndex), defaultSortField) &&
            column.apiSort,
        );
      if (!sortColumn) {
        if (apiSortColumns.length) {
          throw new Error(
            `Could not find sort column for ${String(sortField)}`,
          );
        }
        return;
      }

      if (sortColumn.apiSort && onViewChange) {
        const data = {
          page,
          pageSize,
          sort: [
            {
              name: sortColumn.apiSort,
              direction: getApiSortDirection(
                sortDirection,
                defaultSortDirection,
              ),
            },
          ],
        };

        onViewChange(data);
      }
    },
    [
      defaultSortDirection,
      onViewChange,
      columns,
      defaultSortField,
      apiSortColumns.length,
    ],
  );

  const { currentPage, pageSize, onPaginationChange } =
    usePaginationQueryParams({
      currentPageName: getParamName('page'),
      pageSizeName: getParamName('pageSize'),
      defaultPageSize: rest.pagination
        ? rest.pagination.defaultPageSize
        : undefined,
      onChange: (page, pageSize) =>
        onViewChangeCb(page, pageSize, sortField, sortDirection),
    });

  const onTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      _f: any,
      sorter: SorterResult<T> | SorterResult<T>[],
    ) => {
      if (sorter && !Array.isArray(sorter)) {
        const newSortDirection = getApiSortDirection(
          sorter.order,
          defaultSortDirection,
        );
        const newSortField = sorter.columnKey as keyof T;
        setSortField(newSortField);
        setSortDirection(newSortDirection);

        onViewChangeCb(
          pagination.current ?? currentPage,
          pagination.pageSize ?? pageSize,
          newSortField,
          newSortDirection,
        );
      }
    },
    [
      setSortField,
      setSortDirection,
      currentPage,
      pageSize,
      defaultSortDirection,
      onViewChangeCb,
    ],
  );

  useEffect(() => {
    // on mount, publish the current table view if this is an API-paginated table
    if (apiSortColumns.length) {
      onViewChangeCb(currentPage, pageSize, sortField, sortDirection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();
  const onCell = useCallback(
    (item: T) => ({
      onClick: rowRoute
        ? () => {
            history.push({
              ...history.location,
              // we don't always want to include search params when clicking a row, so only do it when requested
              // need to use window.location to get the current search params because useQueryParamState doesn't set them on React Router history to avoid re-mounting the whole app and losing state
              ...(rowRouteIncludeSearch ? window.location : {}),
              pathname: rowRoute(item),
            });

            return item;
          }
        : undefined,
    }),
    [history, rowRoute, rowRouteIncludeSearch],
  );

  const isPrint = useMediaQuery({ print: true });

  const onExport = () => {
    if (dataSource && exportCsv) {
      const configs = Array.isArray(exportCsv) ? exportCsv : [exportCsv];

      configs.forEach((config, i) =>
        // Safari doesnt like multiple concurrent downloads, so stagger them
        setTimeout(
          () =>
            downloadCsv({
              // any[] to get around readonly TS error
              data: config.transformer(dataSource as any[]),
              ...config,
            }),
          i * 1000,
        ),
      );
    }
  };

  const [searchString, setSearchString] = useQueryParamState<string>(
    getParamName('search'),
  );
  const data: readonly T[] = useMemo(() => dataSource ?? [], [dataSource]);
  const prevColumns = usePrevious(columns);
  // memoise columns here so consumers don't have to
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoColumns = useMemo(() => columns, [isEqual(columns, prevColumns)]);
  const filteredRows = useFuzzySearchTableRows({
    columns: memoColumns,
    data,
    searchString,
  });
  const searchTooltip = getSearchTooltip(columns);

  return (
    <>
      <HStack space="2" mb="4">
        {headerLeft}

        {searchTooltip && (
          <Box flex={1}>
            <Input
              prefix={<SvgSearch />}
              placeholder={searchTooltip}
              value={searchString}
              onChange={e => setSearchString(e.target.value)}
            />
          </Box>
        )}

        {exportCsv && (
          <Button onPress={onExport} disabled={!dataSource?.length} size="md">
            Export
          </Button>
        )}

        {headerRight}
      </HStack>

      <div
        className={classNames({
          'overflow-x-auto': !isPrint && !rest.scroll?.x && !allowOverflow,
        })}
      >
        <AntTable
          className={classNames('xo-table', className, {
            'xo-table-striped': striped && !isPrint,
            'xo-table-clickable': rowRoute,
          })}
          pagination={{
            current: currentPage,
            onChange: onPaginationChange,
            pageSize,
            showSizeChanger: true,
            total: totalRecords,
          }}
          onChange={onTableChange}
          bordered={true}
          rowKey={item => (item as any).id}
          dataSource={filteredRows}
          {...rest}
        >
          {columns
            .filter(column => !column.admin || isXoAdmin)
            .filter(column => !column.hideOnPrint || !isPrint)
            .map(column => (
              <Column
                key={column.dataIndex as string}
                defaultSortOrder={
                  sortDirection && isEqual(sortField, String(column.dataIndex))
                    ? toSortOrder(sortDirection)
                    : undefined
                }
                onCell={!column.suppressClick && rowRoute ? onCell : undefined}
                width={`${100 / columns.length}%`}
                sorter={column.sorter ?? !!column.apiSort}
                {...column}
              />
            ))}
        </AntTable>
      </div>
    </>
  );
};
