import { validate as validateUuid } from 'uuid';
import { ApiSupportedPhoneRegion } from 'xo/graphql/api/enums/supported-phone-region.generated';
import { SERVER_ERROR } from './constants';
import { containsOnlyPhoneChars } from './utils/phone-utils';
import { isValidPhoneNumber } from './validation';

export const cognitoFormattedPhoneNumberIfPhone = async (
  phoneOrEmail?: string,
) => {
  if (
    phoneOrEmail &&
    phoneOrEmail.length > 0 &&
    // Avoid network roundtrips for obviously-not-a-phone.
    //
    // anything with @ in it, or letters:
    containsOnlyPhoneChars(phoneOrEmail) &&
    // UUIDs: some users created earlier have UUID usernames in Cognito, and these _must not_ be
    // corrupted (a UUID is too long to ever be a valid phone numbers, in any region, we believe)
    !validateUuid(phoneOrEmail)
  ) {
    const { e164 } = await isValidPhoneNumber({
      phone: phoneOrEmail,
      phoneRegion: ApiSupportedPhoneRegion.Au,
      // silence errors here
      onError: _ => {},
    });

    if (e164) {
      // It's a valid phone number and the BE has told us the formatting it uses in Cognito... so
      // let's use that!
      return e164;
    }
    // Otherwise, an invalid phone so probably an email, use that without edits.
  }

  return phoneOrEmail?.trim() ?? '';
};

export interface CognitoError {
  name: string;
  message: string;
}

export enum AccountErrorType {
  BadCredentials,
  MaxAttemptsExceeded,
  ExpiredCode,
  InvalidState,
  Unknown,
  Network,
  Custom,
}

export type AccountErrorModel = { type: AccountErrorType; message?: string };

// Errors for each Cognito API listed under Actions: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/Welcome.html
export const cognitoErrorTransformer = (
  cognitoError: unknown,
  isAutoLogin?: boolean,
): AccountErrorModel => {
  const { name: code, message } = (cognitoError ?? {}) as CognitoError;

  if (code === 'NetworkError') {
    return { type: AccountErrorType.Network };
  }

  if (
    code === 'TooManyFailedAttemptsException' ||
    code === 'LimitExceededException' ||
    (code === 'NotAuthorizedException' &&
      message === 'Password attempts exceeded')
  ) {
    return { type: AccountErrorType.MaxAttemptsExceeded };
  }

  if (code === 'ExpiredCodeException') {
    return { type: AccountErrorType.ExpiredCode };
  }

  // Can happen when resetting without initial login
  if (
    code === 'NotAuthorizedException' &&
    message === 'User password cannot be reset in the current state.'
  ) {
    return { type: AccountErrorType.InvalidState };
  }

  // Can happen when user clicks "Set password" link again sometime after account setup
  if (code === 'NotAuthorizedException' && isAutoLogin) {
    return { type: AccountErrorType.ExpiredCode };
  }

  if (
    code === 'NotAuthorizedException' ||
    code === 'UserNotFoundException' ||
    code === 'InvalidParameterException' ||
    code === 'InvalidPasswordException'
  ) {
    return { type: AccountErrorType.BadCredentials };
  }

  return { type: AccountErrorType.Unknown, message: SERVER_ERROR };
};
