import dayjs from 'dayjs';
import { get, isEqual } from 'lodash';
import { FieldValues, Path } from 'react-hook-form';
import { PasswordStrength } from 'xo/components/password-strength-bar';
import { ApiSupportedPhoneRegion } from 'xo/graphql/api/enums/supported-phone-region.generated';
import { PhoneSupport } from 'xo/rest-api';
import {
  IsValidPhoneNumberParams,
  isValidEmail,
  isValidPhoneNumber,
} from 'xo/validation';
import { z } from 'zod';
import { countryCodeOptions } from './phone-utils';
import { hasValue } from './validation-utils';

export const requiredMessage = (label: string) => `${label} is required`;
export const requiredString = (label: string) => {
  const message = requiredMessage(label);
  return z
    .string({
      invalid_type_error: message,
      required_error: message,
    })
    .min(1, message);
};

export const requiredBoolean = (label: string) => {
  const message = requiredMessage(label);
  return z.boolean({
    invalid_type_error: message,
    required_error: message,
  });
};

export const optionalBoolean = () => z.boolean().optional().nullable();

export const requiredInteger = (label: string) => {
  const message = requiredMessage(label);
  return z.coerce.number({
    invalid_type_error: 'Invalid',
    required_error: message,
  });
};

export const optionalInteger = ({
  min,
}: { min?: { value: number; message: string } } = {}) => {
  let rule = z.coerce
    .number({
      invalid_type_error: 'Invalid',
    })
    .int({
      message: 'Invalid',
    });

  if (min) {
    rule = rule.min(min.value, min.message);
  }

  return rule.optional();
};

export const requiredFloat = ({
  min,
}: { min?: { value: number; message: string } } = {}) => {
  let rule = z.coerce.number({
    invalid_type_error: 'Invalid',
  });

  if (min) {
    rule = rule.min(min.value, min.message);
  }

  return rule;
};

export const optionalFloat = ({
  min,
}: { min?: { value: number; message: string } } = {}) => {
  return requiredFloat({ min }).optional();
};

export const optionalString = ({
  requiredLength,
  lengthMessage,
}: { requiredLength?: number; lengthMessage?: string } = {}) => {
  const base = z.string({ coerce: true });
  // We treat empty string as equivalent to undefined/null, but .length() doesn't
  const rule = requiredLength
    ? base.length(requiredLength, lengthMessage).or(z.literal(''))
    : base;
  return rule.optional();
};

export const optionalUrl = () =>
  z.string().url().or(z.literal('')).optional().nullable();

export const requiredEnum = <V extends string>(
  label: string,
  enumObject: Record<string, V>,
) => {
  const values = Object.values(enumObject);
  if (!values.length) {
    throw new Error('Enum with no values!');
  }
  const required = requiredMessage(label);
  return z.enum([values[0], ...values.slice(1)], {
    // every error is 'required' for now, on the assumption that the fields will be filled out via a
    // <Select>, and so there's no scope for adding non-valid values, just not adding one at all
    errorMap: () => ({ message: required }),
  });
};

export const requiredDayjs = (label: string) =>
  z.any().refine(input => dayjs.isDayjs(input), requiredMessage(label));

export const optionalDayjs = () =>
  z
    .any()
    .optional()
    .refine(input => !input || dayjs.isDayjs(input));

export const streetAddressSchema = z.object({
  countryCode: optionalString(),
  line1: optionalString().nullish(),
  line2: optionalString().nullish(),
  postcode: optionalString(),
  state: optionalString(),
  suburb: optionalString(),
});

export const positionSchema = z.object({
  lat: requiredFloat(),
  lng: requiredFloat(),
  alt: optionalFloat(),
  accLatlng: optionalFloat(),
  accAlt: optionalFloat(),
});

export const countryNameByAbbr: Record<ApiSupportedPhoneRegion, string> = {
  [ApiSupportedPhoneRegion.Fj]: 'Fijian',
  [ApiSupportedPhoneRegion.Au]: 'Australian',
  [ApiSupportedPhoneRegion.Nz]: 'New Zealand',
  [ApiSupportedPhoneRegion.Ph]: 'Philippine',
  [ApiSupportedPhoneRegion.Us]: 'US',
};

export const optionalPic = (label: string) =>
  optionalString({
    // "A Property Identification Code (PIC) is an eight-character code"
    // https://www.integritysystems.com.au/on-farm-assurance/property-identification-code-pic/
    requiredLength: 8,
    lengthMessage: `${label} must contain exactly 8 characters`,
  });

export const optionalCheckboxGroup = () =>
  z.record(z.string(), z.boolean().optional()).optional();

export const optionalEmail = () =>
  z
    .string()
    .optional()
    .refine(s => !s || isValidEmail(s), 'Please provide a valid email');

export const optionalPhone = ({
  requireMobile,
  onError,
  origin,
}: Pick<IsValidPhoneNumberParams, 'onError' | 'origin'> & {
  requireMobile: boolean;
}) =>
  z
    .string()
    .optional()
    .superRefine(async (phone, ctx) => {
      const phoneRegion = countryCodeOptions.find(({ label }) =>
        phone?.replaceAll(' ', '').startsWith(label),
      )?.value;

      // optional phone => empty phone is fine
      if (!phone) return;

      const result = phoneRegion
        ? await isValidPhoneNumber({
            phone,
            phoneRegion,
            origin,
            onError,
          })
        : undefined;

      if (!result || !result?.valid || !result?.supportsSms) {
        // the number looks invalid flatly invalid
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Please provide a valid ${requireMobile ? 'mobile' : 'phone'} number`,
        });
        return;
      }

      if (requireMobile && result.supportsSms !== 'SUPPORTED') {
        // we needed SMS support and this number doesn't support it
        const errors: Record<Exclude<PhoneSupport, 'SUPPORTED'>, string> = {
          UNSUPPORTED_REGION: `Please provide a mobile number from a different country, or use email`,
          UNSUPPORTED_NUMBER_TYPE: `Please provide a valid mobile number`,
        };

        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: errors[result.supportsSms],
        });
        return;
      }
    });

export const requiredTrue = (message: string) =>
  z.boolean().refine(value => !!value, message);

export const minimumPasswordStrength = () =>
  z.number().min(PasswordStrength.Medium);

export const fieldsMustMatch =
  <TFieldValues extends FieldValues>(
    fieldA: Path<TFieldValues>,
    fieldB: Path<TFieldValues>,
    message: string,
  ) =>
  (values: TFieldValues, ctx: z.RefinementCtx) => {
    if (!isEqual(get(values, fieldA), get(values, fieldB))) {
      [fieldA, fieldB].forEach(name =>
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [name],
          message,
        }),
      );
    }
  };

export const eitherRequired =
  <TFieldValues extends FieldValues>(
    fieldA: { path: Path<TFieldValues>; label: string },
    fieldB: { path: Path<TFieldValues>; label: string },
  ) =>
  (values: TFieldValues, ctx: z.RefinementCtx) => {
    if (
      !hasValue(get(values, fieldA.path)) &&
      !hasValue(get(values, fieldB.path))
    ) {
      [fieldA, fieldB].forEach(field =>
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [field.path],
          message: `Please specify ${fieldA.label} or ${fieldB.label} or both`,
        }),
      );
    }
  };
