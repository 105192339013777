import dayjs from 'dayjs';
import { ApiInternalVisitorStatus } from 'xo/graphql/api/enums/internal-visitor-status.generated';
import { InternalVisitorStatus, VisitState } from 'xo/rest-api';
import { UserModel, VisitModel } from '../../models/visitor-log-models';
import {
  VISIT_REASON_OTHER_OPTION,
  VISIT_REASON_OTHER_PREFIX,
} from '../app-constants';

export const canEditVisit = (visit: VisitModel) =>
  !(
    [
      'CANCELLED',
      'LEAVING_VISITOR_WAITING',
      'ONSITE_VISITOR_COMPLETED_HOST_NOTIFIED',
      'ONSITE_VISITOR_COMPLETED_APPROVED_WITHOUT_REVIEW',
      'EARLY_REJECTED',
      'ONSITE_REJECTED',
      'REQUESTED_REJECTED',
      'COMPLETED',
      'DELETED',
    ] as VisitState[]
  ).includes(visit.status) &&
  (visit.entryDateTime.isAfter(dayjs(), 'day') ||
    visit.entryDateTime.isSame(dayjs(), 'day'));

export const canCancelVisit = (visit: VisitModel) =>
  !(
    [
      'CANCELLED',
      'EARLY_REJECTED',
      'ONSITE_REJECTED',
      'REQUESTED_REJECTED',
      'LEAVING_VISITOR_WAITING',
      'COMPLETED',
      'DELETED',
    ] as VisitState[]
  ).includes(visit.status);

export const visitIsCheckInOnly = (
  visit?: Pick<VisitModel, 'forms' | 'history'>,
) =>
  !!(
    !visit?.forms.some(f => f.kind === 'ONSITE' && f.riskAssessment) &&
    visit?.history.some(h => h.status === 'SIGN_IN_VISITOR_WAITING')
  );

export const combinePurpose = ({
  purpose,
  otherPurpose,
}: {
  purpose: string;
  otherPurpose?: string;
}) => {
  if (purpose === VISIT_REASON_OTHER_OPTION) {
    return `${VISIT_REASON_OTHER_PREFIX}${otherPurpose}`;
  }
  return purpose;
};

export const internalVisitorStatusAtOrg = ({
  visitor,
  organisationId,
}: {
  visitor: Pick<
    UserModel,
    | 'internalVisitorStatusAtOrganisation'
    | 'internalVisitorStatusOrganisationId'
  >;
  organisationId: string;
}) =>
  (visitor.internalVisitorStatusOrganisationId === organisationId
    ? visitor.internalVisitorStatusAtOrganisation
    : undefined) ?? ApiInternalVisitorStatus.External;

// InternalSelfReported is deprecated but the BE may still serve it up, but we want to treat these the same
export const internalVisitorStatuses = new Set([
  ApiInternalVisitorStatus.InternalConfirmed,
  ApiInternalVisitorStatus.InternalSelfReported,
]);

export const isInternalVisitor = (
  status?: ApiInternalVisitorStatus | InternalVisitorStatus,
): boolean =>
  Boolean(
    status && internalVisitorStatuses.has(status as ApiInternalVisitorStatus),
  );
