import { Dayjs } from 'dayjs';
import { formatIsoDate } from 'xo/date-utils';
import { ApiInternalVisitorStatus } from 'xo/graphql/api/enums/internal-visitor-status.generated';
import { ApiNotificationMethodsInput } from 'xo/graphql/api/inputs/notification-methods-input.generated';
import { CovidVaccinationCertificateSightingRequest } from 'xo/rest-api';
import { internalVisitorStatuses as _internalVisitorStatuses } from '../app/visits/visit-utils';
import {
  CovidVaccinationCertificateSightingModel,
  NotificationsModel,
  UserVisitorModel,
} from '../models/visitor-log-models';

export const notificationsTransformer = (
  n: NotificationsModel,
): ApiNotificationMethodsInput => ({
  sms: n.includes('sms'),
  email: n.includes('email'),
  devicePush: n.includes('devicePush'),
  whatsapp: n.includes('whatsapp'),
});

export const datetimeTransformer = (d: Dayjs) =>
  d.format('YYYY-MM-DDTHH:mm:ss');

export const visitorTransformer = (
  v: UserVisitorModel,
  internalVisitorStatuses: typeof _internalVisitorStatuses,
) => ({
  id: v.id,
  name: v.name,
  // inserting and then removing a value can result in a field value of "" (empty string), which
  // isn't a correct representation of missing.
  email: !!v.email ? v.email : undefined,
  phone: !!v.phone ? v.phone : undefined,
  visitorIsInternal: internalVisitorStatuses.has(
    v.internalVisitorStatusAtVisitOrg as ApiInternalVisitorStatus,
  ),
  company: v.company,
});

export const covidVaccinationSightingTransformer = (
  data: CovidVaccinationCertificateSightingModel,
): CovidVaccinationCertificateSightingRequest => ({
  sightedBy:
    data.sightedDate && data.sightedBy?.id
      ? { id: data.sightedBy.id }
      : undefined,
  sightedDate: data.sightedDate ? formatIsoDate(data.sightedDate) : undefined,
});
