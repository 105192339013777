import { ApiFormKind } from 'xo/graphql/api/enums/form-kind.generated';
import logger from 'xo/logger';
import { toId } from 'xo/utils/string-utils';

export const getFormRoute = (
  kind: ApiFormKind,
  // FIXME Use ApiFormSummaryModel when it's available in GraphQL
  visit: {
    id: string;
    forms: { kind: ApiFormKind | string; token?: string }[];
  },
) => {
  const form = visit.forms.find(f => f.kind === kind);
  if (!form) {
    logger.warn(`No kind ${kind} found for visit ${visit.id}`);
    return '';
  }

  const id = (
    [
      ApiFormKind.Previsit,
      ApiFormKind.Onsite,
      ApiFormKind.Early,
      ApiFormKind.Leaving,
      ApiFormKind.Incomplete,
    ] as ApiFormKind[]
  ).includes(kind)
    ? form.token
    : visit.id;

  return visitorRoutes.forms[kind](id);
};

const formRoute = (path: string) => (idOrToken?: string) =>
  `/${path}/${idOrToken ?? ':idOrToken'}`;

export const formRoutes: { [kind in ApiFormKind]: (id?: string) => string } = {
  PREVISIT: formRoute('previsit'),
  ONSITE: formRoute('onsite'),
  ONSITE_HOST: formRoute('review'),
  EARLY: formRoute('early'),
  EARLY_HOST: formRoute('early-review'),
  LEAVING: formRoute('signout'),
  INCOMPLETE: formRoute('incomplete'),
  PREVISIT_HOST: () => '',
};

export const kioskRoot = (siteShortCode?: string) =>
  `/site-kiosk/${siteShortCode ?? ':siteShortCode'}`;
export const siteRoot = (siteShortCode?: string) =>
  `/check-in/${siteShortCode ?? ':siteShortCode'}`;
export const orgRoot = (orgShortCode?: string) =>
  `/org/${orgShortCode ?? ':orgShortCode'}`;

export const COMPLETION_PAGE_PATH = 'completed';
export const CHECK_IN_PATHS = {
  siteLanding: 'site',
  load: 'load',
  verify: 'verify',
  visits: 'visits',
  visitor: 'visitor',
  assessmentCompleted: COMPLETION_PAGE_PATH,
};

export interface VisitorAppRouteParams {
  orgShortCode: string | undefined;
  siteShortCode: string | undefined;
  kioskMode: boolean;
  orgDisabled?: boolean;
  kioskDisabled?: boolean;
  // controls whether to return resolved route or route config
  routeConfig?: boolean;
}

export const visitorAppRoot = ({
  orgShortCode,
  siteShortCode,
  kioskMode,
  orgDisabled,
  kioskDisabled,
  routeConfig,
}: VisitorAppRouteParams) =>
  orgShortCode && !orgDisabled
    ? orgRoot(routeConfig ? undefined : orgShortCode)
    : kioskMode && !kioskDisabled
      ? kioskRoot(routeConfig ? undefined : siteShortCode)
      : siteRoot(routeConfig ? undefined : siteShortCode);

type VisitorPathParams = { path: string } & Pick<
  VisitorAppRouteParams,
  'orgDisabled' | 'kioskDisabled' | 'routeConfig'
>;

const visitorRoute =
  ({ path, ...rest }: VisitorPathParams) =>
  (params: VisitorAppRouteParams) =>
    `${visitorAppRoot({ ...params, ...rest })}/${path}`;

const kioskRoute =
  ({ path, ...rest }: VisitorPathParams) =>
  (params: Omit<VisitorAppRouteParams, 'orgShortCode'>) =>
    `${visitorAppRoot({ orgShortCode: undefined, ...params, ...rest })}/${path}`;

// nests a form route under one of the check-in app roots
export const getCheckInFormRoute = ({
  root,
  kind,
  idOrToken,
}: {
  root: string;
  kind: ApiFormKind;
  idOrToken?: string;
}) => `${root}${visitorRoutes.forms[kind](idOrToken)}`;

export const visitorRoutes = {
  forms: formRoutes,
  terms: '/terms-and-conditions',
  siteRoot,
  kioskRoot,
  orgRoot,
  visitorRoute,
  delivery: visitorRoute({ path: 'delivery', orgDisabled: true }),
  requestVisit: visitorRoute({ path: 'visit' }),
  kioskVisits: kioskRoute({ path: 'kiosk-visits', orgDisabled: true }),
  kioskVisit: ({
    idOrToken,
    routeConfig,
  }: {
    idOrToken?: string;
    routeConfig?: boolean;
  }) =>
    kioskRoute({
      path: `kiosk-visits/${idOrToken ?? ':idOrToken'}`,
      orgDisabled: true,
      routeConfig,
    }),
  verify: visitorRoute({ path: CHECK_IN_PATHS.verify, kioskDisabled: true }),
};

export const appRoutes = {
  root: '/',
  terms: '/terms-and-conditions',
  account: {
    login: '/login',
    forgotPassword: '/login/forgot-password',
    resetPassword: '/login/reset-password',
    setPassword: '/login/set-password',
    enterMfa: '/login/mfa',
    signUp: '/sign-up',
  },
  schedule: '/schedule',
  outstandingTasks: '/outstanding-tasks',
  visits: {
    new: '/visits/new',
    details: (visitId?: string) => `/visits/${visitId ?? ':id'}/details`,
    seriesDetails: (visitSeriesId?: string) =>
      `/visit-series/${visitSeriesId ?? ':id'}/details`,
    seriesList: (visitSeriesId?: string) =>
      `/visit-series/${visitSeriesId ?? ':id'}/details/list`,
    seriesCancel: (visitSeriesId?: string) =>
      `/visit-series/${visitSeriesId ?? ':id'}/details/cancel`,
    cancel: (visitId?: string) => `/visits/${visitId ?? ':id'}/cancel`,
    riskAssessment: (visitId?: string) =>
      `/visits/${visitId ?? ':id'}/details#${toId('Risk assessment')}`,
    admin: (visitId?: string) => `/visits/${visitId ?? ':id'}/admin`,
  },
  deliveries: {
    new: '/deliveries/new',
    details: (deliveryId?: string) =>
      `/deliveries/${deliveryId ?? ':id'}/details`,
    cancel: (deliveryId?: string) =>
      `/deliveries/${deliveryId ?? ':id'}/cancel`,
    admin: (deliveryId?: string) => `/deliveries/${deliveryId ?? ':id'}/admin`,
  },
  reporting: {
    root: '/reporting',
    visitorLog: '/reporting/visits',
  },
  review: {
    onsite: (visitId?: string) => `/review/${visitId ?? ':id'}`,
    early: (visitId?: string) => `/early-review/${visitId ?? ':id'}`,
    request: (visitId?: string) => `/review-request/${visitId ?? ':id'}`,
  },
  exports: {
    download: (exportId?: string) => `/exports/${exportId ?? ':id'}/download`,
  },
  settings: {
    root: '/settings',
    users: '/settings/users',
    userDetails: (userId?: string) => `/settings/users/${userId ?? ':id'}`,
    userNew: '/settings/users/new',
    sites: '/settings/sites',
    siteDetails: (siteId?: string) => `/settings/sites/${siteId ?? ':id'}`,
    siteNew: '/settings/sites/new',
    quarantine: '/settings/quarantine',
    notifications: '/settings/notifications',
    notificationsDetails: (id?: string) =>
      `/settings/notifications/${id ?? ':id'}`,
    addressBook: {
      contacts: '/settings/address-book/contacts',
      contactDetails: (id?: string) =>
        `/settings/address-book/contacts/details/${id ?? ':id'}`,
      locations: '/settings/address-book/locations',
      locationDetails: (id?: string) =>
        `/settings/address-book/locations/details/${id ?? ':id'}`,
    },
    vehicles: '/settings/vehicles',
    vehicleDetails: (id?: string) =>
      `/settings/vehicles/details/${id ?? ':id'}`,
  },
  admin: {
    root: '/admin',
    dashboard: '/admin/dashboard',
    system: '/admin/system',
    allSites: '/admin/all-sites',
    allUsers: '/admin/all-users',
    test: '/admin/test',
    geofences: '/admin/geofences',
    vehicles: '/admin/vehicles',
    vehicleEvents: '/admin/vehicle-events',
    organisations: '/admin/organisations',
    organisationDetails: (orgId?: string) =>
      `/admin/organisations/${orgId ?? ':id'}`,
    organisationNew: '/admin/organisations/new',
    outbreaks: '/admin/outbreaks',
    outbreakDetails: (id?: string) => `/admin/outbreaks/details/${id ?? ':id'}`,

    // Temporary unitl the new user settings page is finalised
    users: '/admin/users',
    userDetails: (id?: string) => `/admin/users/${id ?? ':id'}`,
  },
  mobile: {
    // Routes handled by the web app
    // Be aware that the link registered with the mobile OS has a trailing slash,
    // without which the URL won't be recognised (if that's what you want)
    short: '/m',
    long: '/mobile',
    switchToApp: '/mobile/switch',
  },
  arrivalMetrics: '/arrival-metrics',
};

const transportAppRoot = '/transport';
export const transportRoutes = {
  root: transportAppRoot,
  tripDetails: (tripId?: string) =>
    `${transportAppRoot}/trip/${tripId ?? ':id'}`,
};

const consignmentNoteAppRoot = '/consignment-notes';
const consignmentNoteAppDetails = (id?: string) =>
  `${consignmentNoteAppRoot}/details/${id ?? ':id'}`;
export const consignmentNoteRoutes = {
  root: consignmentNoteAppRoot,
  details: consignmentNoteAppDetails,
  detailsAttachment: (id?: string, documentId?: string) =>
    `${consignmentNoteAppDetails(id)}/attachment/${documentId ?? ':documentId'}`,
};

const arrivalDashboardAppRoot = '/arrival-dashboard';
export const arrivalDashboardRoutes = {
  root: arrivalDashboardAppRoot,
  new: `${arrivalDashboardAppRoot}/new`,
  details: (movementId?: string) =>
    `${arrivalDashboardAppRoot}/details/${movementId ?? ':id'}`,
};
