import classNames from 'classnames';
import { Box, BoxProps } from 'xo/core';

export interface FixedHeaderProps extends BoxProps {
  children: React.ReactNode;
  className?: string;
}

export const FixedHeader = ({
  className,
  children,
  ...boxProps
}: FixedHeaderProps) => {
  const height = boxProps.h ?? boxProps.height ?? 16;

  return (
    <>
      <Box
        width="full"
        h={height}
        bg="white"
        className={classNames(className, 'fixed print:hidden')}
        style={{ zIndex: 50 }}
        {...boxProps}
      >
        {children}
      </Box>

      {/* Offset the page content so it never intersects with the header */}
      <Box className="invisible w-full print:hidden" h={height} />
    </>
  );
};
