import { isBoolean, isFinite } from 'lodash';

// Allow unversioned UUIDs to pass validation too, since we might use mock ones in the API that don't validate with uuid.validate
export const validateUuidLike = (test: string) =>
  !!/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/g.test(
    test,
  );

export const hasValue = (value: any) =>
  Boolean(
    (Array.isArray(value) && (value as any[]).length > 0) ||
      isFinite(value) ||
      isBoolean(value) ||
      (value && !Array.isArray(value)),
  );

// useful to make switch statements exhaustive and type-checked
export const unreachable = (n: never): never => {
  throw new Error("Shouldn't be reachable");
};
