// generated by constants-typenames-plugin.js

export enum TypeName {
  AddressBookContact = 'AddressBookContact',
  AddressBookSite = 'AddressBookSite',
  Admin = 'Admin',
  ArrivalDashboardMetric = 'ArrivalDashboardMetric',
  BaseEntryPage = 'BaseEntryPage',
  ConsignmentNote = 'ConsignmentNote',
  ConsignmentNoteDocument = 'ConsignmentNoteDocument',
  ConsignmentNoteIdReservation = 'ConsignmentNoteIdReservation',
  ConsignmentNoteIdReservationBatch = 'ConsignmentNoteIdReservationBatch',
  ConsignmentNoteStock = 'ConsignmentNoteStock',
  ConsignmentNoteStockLoadingChecklist = 'ConsignmentNoteStockLoadingChecklist',
  CovidVaccinationCertificateSighting = 'CovidVaccinationCertificateSighting',
  CurrentUser = 'CurrentUser',
  Definition = 'Definition',
  Delivery = 'Delivery',
  Driver = 'Driver',
  FileAttachment = 'FileAttachment',
  FileExport = 'FileExport',
  Filter = 'Filter',
  FindOrganisation = 'FindOrganisation',
  Form = 'Form',
  FormCurrentUpdater = 'FormCurrentUpdater',
  Geofence = 'Geofence',
  IngestScheduledMovementSpreadsheetError = 'IngestScheduledMovementSpreadsheetError',
  MobileAppConfig = 'MobileAppConfig',
  Mutation = 'Mutation',
  NotificationMethods = 'NotificationMethods',
  OpenOrganisation = 'OpenOrganisation',
  OpenSite = 'OpenSite',
  Organisation = 'Organisation',
  Outbreak = 'Outbreak',
  Page = 'Page',
  Place = 'Place',
  PlotlyData = 'PlotlyData',
  PlotlyNamedTrace = 'PlotlyNamedTrace',
  Position = 'Position',
  PositionDirectionInTime = 'PositionDirectionInTime',
  PositionInTime = 'PositionInTime',
  Query = 'Query',
  Question = 'Question',
  QuestionAssessment = 'QuestionAssessment',
  QuestionCalendar = 'QuestionCalendar',
  QuestionDependency = 'QuestionDependency',
  QuestionExpects = 'QuestionExpects',
  QuestionExpectsOption = 'QuestionExpectsOption',
  QuestionMostRecentVisit = 'QuestionMostRecentVisit',
  QuestionPanel = 'QuestionPanel',
  QuestionRemembered = 'QuestionRemembered',
  QuestionReview = 'QuestionReview',
  QuestionReviewOption = 'QuestionReviewOption',
  QuestionWarning = 'QuestionWarning',
  Questionnaire = 'Questionnaire',
  QuestionnaireCondition = 'QuestionnaireCondition',
  RequiredAction = 'RequiredAction',
  RoadVehicle = 'RoadVehicle',
  ScheduledMovement = 'ScheduledMovement',
  ScheduledMovementDaySummary = 'ScheduledMovementDaySummary',
  ScheduledMovementEditability = 'ScheduledMovementEditability',
  ScheduledMovementIngestion = 'ScheduledMovementIngestion',
  ScheduledMovementKillDaySummary = 'ScheduledMovementKillDaySummary',
  ScheduledMovementOnTimeSummary = 'ScheduledMovementOnTimeSummary',
  ScheduledMovementQuantitySummary = 'ScheduledMovementQuantitySummary',
  ScheduledMovementStock = 'ScheduledMovementStock',
  ScheduledMovementStockEditability = 'ScheduledMovementStockEditability',
  ScheduledMovementSummary = 'ScheduledMovementSummary',
  ScheduledMovementUnloadedSummary = 'ScheduledMovementUnloadedSummary',
  SendTestNotificationResult = 'SendTestNotificationResult',
  Site = 'Site',
  SiteGroup = 'SiteGroup',
  SiteMovementMatrix = 'SiteMovementMatrix',
  SiteMovementRule = 'SiteMovementRule',
  SiteMovementSiteOverride = 'SiteMovementSiteOverride',
  StreetAddress = 'StreetAddress',
  SubLoad = 'SubLoad',
  System = 'System',
  TransportSiteNotificationPreferences = 'TransportSiteNotificationPreferences',
  Transporter = 'Transporter',
  Trip = 'Trip',
  TripEvent = 'TripEvent',
  TripExportExcel = 'TripExportExcel',
  TripFilterDefaults = 'TripFilterDefaults',
  TripsFilter = 'TripsFilter',
  User = 'User',
  UserDevicePushToken = 'UserDevicePushToken',
  UserError = 'UserError',
  UserErrorConflict = 'UserErrorConflict',
  UserPreferences = 'UserPreferences',
  UserRole = 'UserRole',
  VaccineStatuses = 'VaccineStatuses',
  Vehicle = 'Vehicle',
  VehicleConfig = 'VehicleConfig',
  VehicleEvent = 'VehicleEvent',
  VehicleEventEta = 'VehicleEventEta',
  Visit = 'Visit',
  VisitOtherSite = 'VisitOtherSite',
  VisitSeries = 'VisitSeries',
  VisitSitesVisitedWithinOrgQuarantine = 'VisitSitesVisitedWithinOrgQuarantine',
  VisitStateHistoryItem = 'VisitStateHistoryItem',
  Visitor = 'Visitor',
  VisitorSiteNotificationPreferences = 'VisitorSiteNotificationPreferences',
  WeatherObservation = 'WeatherObservation',
  WebAppConfig = 'WebAppConfig',
}
