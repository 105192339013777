import { usePrevious, useTimeout } from '@mantine/hooks';
import { isEqual, isString, noop } from 'lodash';
import { AnimatePresence, MotiView } from 'moti';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ViewStyle } from 'react-native';
import { Easing } from 'react-native-reanimated';
import { HelpTooltip } from 'xo/components/help-tooltip';
import { Box, BoxProps, Text } from 'xo/core';
import { useFlag } from 'xo/hooks/component-hooks';
import { borderRadii } from 'xo/styles/restyle/border-radii';
import { borderWidth } from 'xo/styles/restyle/border-width';
import { colors } from 'xo/styles/restyle/colors';
import { spacing } from 'xo/styles/restyle/spacing';
import { Color } from 'xo/styles/restyle/theme';

const HIGHLIGHT_MS = 3000;
// 'transparent' can render a black border with transitions
const transparent = 'rgba(255,255,255, 0)';
const defaultProps = {
  borderColor: transparent,
  backgroundColor: transparent,
};

const highlightProps = {
  borderColor: colors['blue.400'],
  backgroundColor: colors['grey.100'],
};

const editableProps = {
  ...highlightProps,
  borderColor: colors['grey.300'],
};

const focusedProps = {
  ...highlightProps,
  backgroundColor: colors['blue.50'],
};

export interface FormControlHelperProps extends Omit<BoxProps, 'children'> {
  color?: Color;
  children: string | React.ReactNode;
}

export const FormControlHelper = ({
  children,
  color = 'black',
  ...rest
}: FormControlHelperProps) =>
  isString(children) ? (
    <Box {...rest}>
      <Text color={color} fontSize="sm">
        {children}
      </Text>
    </Box>
  ) : (
    children
  );

interface FormControlContextProps {
  showHighlight?: boolean;
  onFocus: () => void;
  onBlur: () => void;
}

export const FormControlContext = React.createContext<FormControlContextProps>({
  showHighlight: false,
  onFocus: noop,
  onBlur: noop,
});

export type FormControlVariant = 'editable';

export interface FormControlHighlightProps {
  enable: boolean;
  value: any;
  force?: boolean;
  timeoutMs?: number;
}

export interface FormControlProps extends BoxProps {
  label?: string;
  labelRight?: React.ReactNode;
  labelTooltip?: string;
  helperAbove?: FormControlHelperProps['children'];
  helperBelow?: FormControlHelperProps['children'];
  above?: React.ReactNode;
  below?: React.ReactNode;
  fieldError?: string;
  children: React.ReactNode;
  isRequired?: boolean;
  isInvalid?: boolean;
  errorDisplay?: 'none' | 'transparent';
  hidden?: boolean;
  highlight?: FormControlHighlightProps;
  variant?: FormControlVariant;
  autoFocus?: boolean;
  saving?: boolean;
  containerStyleOverrides?: ViewStyle;
}

export const FormControl = (props: FormControlProps) => {
  const {
    children,
    label,
    labelRight,
    labelTooltip,
    helperAbove,
    helperBelow,
    above,
    below,
    fieldError,
    isRequired,
    isInvalid,
    errorDisplay,
    hidden,
    highlight,
    variant,
    autoFocus,
    saving,
    containerStyleOverrides,
    ...rest
  } = props;

  const [showHighlight, setShowHighlight] = useState(highlight?.force);
  const { start, clear } = useTimeout(
    () => setShowHighlight(false),
    highlight?.timeoutMs ?? HIGHLIGHT_MS,
    { autoInvoke: false },
  );

  const prevHighlight = usePrevious(highlight);
  useEffect(() => {
    if (
      highlight?.enable &&
      prevHighlight?.enable &&
      !isEqual(prevHighlight?.value, highlight?.value)
    ) {
      setShowHighlight(true);
      start();
    }
  }, [highlight, prevHighlight, start]);

  useEffect(() => {
    if (!highlight?.enable && prevHighlight?.enable) {
      setShowHighlight(false);
      clear();
    }
  }, [highlight?.enable, setShowHighlight, prevHighlight, clear]);

  const [focused, onFocus, onBlur] = useFlag(!!autoFocus);

  const renderLabelRight = saving ? (
    <ActivityIndicator color={colors['grey.400']} style={{ marginTop: -10 }} />
  ) : (
    labelRight
  );

  return (
    <FormControlContext.Provider value={{ showHighlight, onFocus, onBlur }}>
      <Box {...rest} display={hidden ? 'none' : undefined}>
        <MotiView
          style={{
            paddingHorizontal: spacing['1'],
            paddingVertical: spacing['0.5'],
            borderStyle: 'solid',
            borderWidth: borderWidth['1'],
            borderColor: transparent,
            borderRadius: borderRadii['md'],
            ...containerStyleOverrides,
          }}
          animate={
            focused && label && variant
              ? focusedProps
              : showHighlight
                ? highlightProps
                : variant === 'editable'
                  ? editableProps
                  : defaultProps
          }
          transition={{ easing: Easing.linear, duration: 300 }}
        >
          {label && (
            <Box direction="row" align="center" justify="space-between">
              <Box direction="row" align="center" mb="1">
                {isRequired && (
                  <Text fontSize="lg" mr="0.5" color="orange.700">
                    *
                  </Text>
                )}
                <Text
                  color={showHighlight || focused ? 'blue.600' : 'grey.700'}
                  fontSize="md"
                  textTransform="uppercase"
                >
                  {label}
                </Text>
                {labelTooltip && (
                  <Box alignSelf="baseline" ml="0.5">
                    <HelpTooltip>
                      <Text color="white">{labelTooltip}</Text>
                    </HelpTooltip>
                  </Box>
                )}
              </Box>

              {renderLabelRight && <Box>{renderLabelRight}</Box>}
            </Box>
          )}

          {helperAbove && (
            <FormControlHelper mb="2">{helperAbove}</FormControlHelper>
          )}

          {above}

          {children}

          {below}

          {helperBelow && (
            <FormControlHelper mt="2">{helperBelow}</FormControlHelper>
          )}
        </MotiView>

        {errorDisplay !== 'none' && (
          <Box h="8">
            <AnimatePresence exitBeforeEnter={true}>
              <MotiView
                from={{ opacity: 0 }}
                animate={{
                  opacity: fieldError && errorDisplay !== 'transparent' ? 1 : 0,
                }}
                exit={{ opacity: 0 }}
              >
                <Box direction="row" mt="0.5">
                  <Text color="red.500">* {fieldError}</Text>
                </Box>
              </MotiView>
            </AnimatePresence>
          </Box>
        )}
      </Box>
    </FormControlContext.Provider>
  );
};
