import { Box, HStack, Text } from 'xo/core';

interface CheckInNavBarContentProps {
  title: string;
  icon: JSX.Element;
}

export const CheckInNavBarContent = ({
  title,
  icon,
}: CheckInNavBarContentProps) => {
  return (
    <HStack w="full">
      <Box flex={1}>{icon}</Box>

      <Box flex={2}>
        <Text
          fontWeight="500"
          textAlign="center"
          isTruncated={false}
          fontSize="lg"
        >
          {title}
        </Text>
      </Box>

      <Box flex={1}>
        {
          // Intentionally empty while there is no right button, to maintain correct layout
        }
      </Box>
    </HStack>
  );
};
