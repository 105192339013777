import { AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';
import { sortBy } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  useCurrentUser,
  useRefetchCurrentUser,
} from 'xo/login/current-user-hooks';
import {
  AdminUsageDashboardRequest,
  AdminUsageDashboardResponse as ApiAdminUsageDashboardResponse,
  OrganisationAdminReadModel,
  OrganisationAdminResponse,
  OrganisationCreateRequest,
  OrganisationFindResponse,
  OrganisationListResponse,
  OrganisationUpdateRequest,
} from 'xo/rest-api';
import { PlotlyFigureResponse } from '../app/components/component-models';
import { getApi } from './axios-instance';
import { readUserTransformer } from './transformers';

const adminApi = getApi('admin');

export interface AdminUsageDashboardResponse
  extends ApiAdminUsageDashboardResponse {
  userUsageByDatePlot: PlotlyFigureResponse;
  siteUsageByDatePlot: PlotlyFigureResponse;
  apiUsageByDatePlot: PlotlyFigureResponse;
  overallSiteUsagePlot: PlotlyFigureResponse;
  overallUserUsagePlot: PlotlyFigureResponse;
}

export const useGetUsageDashboard = ({
  siteIds,
  startDatetime,
  endDatetime,
}: {
  siteIds: string[];
  startDatetime?: Dayjs;
  endDatetime?: Dayjs;
}) =>
  useQuery(
    ['adminDashboard', startDatetime, endDatetime, siteIds.sort()],
    () => {
      if (!startDatetime || !endDatetime) return null;

      const data: AdminUsageDashboardRequest = {
        start: startDatetime.format(),
        end: endDatetime.format(),
        sites: siteIds,
      };

      return adminApi
        .post<AdminUsageDashboardResponse>('/dashboard', data)
        .then(response => response.data);
    },
    { enabled: !!(startDatetime && endDatetime && siteIds.length > 0) },
  );

const orgQueryKeys = {
  list: 'organisations',
  org: (id: string) => `organisation:${id}`,
};

export const useGetOrganisations = () =>
  useQuery(orgQueryKeys.list, () => {
    return adminApi
      .get<OrganisationListResponse>('/organisation')
      .then(response => sortBy(response.data.organisations, 'name'));
  });

export const usePostOrganisation = () => {
  const client = useQueryClient();

  return useMutation(
    (organisation: OrganisationAdminReadModel) => {
      const request: OrganisationCreateRequest = {
        id: organisation.id || undefined,
        organisation: {
          name: organisation.name,
          isTest: organisation.isTest ?? false,
          phone: organisation.phone || undefined,
          note: organisation.note,
          modules: organisation.modules,
          airtableId: organisation.airtableId || undefined,
          features: organisation.features || [],
          addressLine1: organisation.addressLine1 || undefined,
          addressLine2: organisation.addressLine2 || undefined,
          addressSuburb: organisation.addressSuburb || undefined,
          addressState: organisation.addressState || undefined,
          addressPostcode: organisation.addressPostcode || undefined,
          addressCountryCode: organisation.addressCountryCode || undefined,
        },
      };

      return adminApi
        .post<OrganisationAdminResponse>('/organisation', request)
        .then(response => {
          const organisation = response.data.organisation;
          client.setQueryData(orgQueryKeys.org(organisation.id), organisation);
          client.setQueryData<OrganisationAdminReadModel[]>(
            orgQueryKeys.list,
            orgs => sortBy((orgs ?? []).concat(organisation), o => o.name),
          );
          return organisation;
        });
    },
    { retry: false },
  );
};

export const usePutOrganisation = () => {
  const client = useQueryClient();
  const refetchCurrentUser = useRefetchCurrentUser();
  const currentUser = useCurrentUser();

  return useMutation(
    (organisation: OrganisationAdminReadModel) => {
      const request: OrganisationUpdateRequest = {
        organisation: {
          name: organisation.name,
          isTest: organisation.isTest ?? false,
          phone: organisation.phone || undefined,
          note: organisation.note,
          modules: organisation.modules,
          airtableId: organisation.airtableId || undefined,
          features: organisation.features || [],
          addressLine1: organisation.addressLine1 || undefined,
          addressLine2: organisation.addressLine2 || undefined,
          addressSuburb: organisation.addressSuburb || undefined,
          addressState: organisation.addressState || undefined,
          addressPostcode: organisation.addressPostcode || undefined,
          addressCountryCode: organisation.addressCountryCode || undefined,
          australianBusinessNumber:
            organisation.australianBusinessNumber || undefined,
          australianCompanyNumber:
            organisation.australianCompanyNumber || undefined,
        },
      };

      return adminApi
        .put<OrganisationAdminResponse>(
          `/organisation/${organisation.id}`,
          request,
        )
        .then(response => {
          const organisation = response.data.organisation;
          client.setQueryData(orgQueryKeys.org(organisation.id), organisation);
          client.setQueryData<OrganisationAdminReadModel[]>(
            orgQueryKeys.list,
            orgs =>
              orgs?.map(o => (o.id === organisation.id ? organisation : o)) ??
              [],
          );

          // remove this once the org page is updating via GraphQL
          if (currentUser.organisation?.id === organisation.id) {
            refetchCurrentUser();
          }

          return organisation;
        });
    },
    { retry: false },
  );
};

export const getOrganisation = ({ type, id }: { type: string; id: string }) =>
  getApi('admin', { notifyOnError: false })
    .get(`/organisation/${type}/${id}`)
    .then((r: AxiosResponse<OrganisationFindResponse>) => ({
      organisation: r.data.organisation,
      supportUser: readUserTransformer(r.data.supportUser, undefined),
    }));
