// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
import { ApiSiteFragment, SiteFragmentDoc } from './site-fragment.generated';
export type ApiVisitorSiteNotificationPreferencesFragment = {
  __typename?: 'VisitorSiteNotificationPreferences';
  deliveryCheckIn: boolean;
  deliveryCheckInIsEditable: boolean;
  deliveryHighRisk: boolean;
  deliveryHighRiskIsEditable: boolean;
  visitHighRisk: boolean;
  visitHighRiskIsEditable: boolean;
  visitLowRisk: boolean;
  visitLowRiskIsEditable: boolean;
  visitSignIn: boolean;
  visitSignInIsEditable: boolean;
  visitorEodSummary: boolean;
  visitorEodSummaryIsEditable: boolean;
  site: { __typename?: 'Site' } & ApiSiteFragment;
};

export const VisitorSiteNotificationPreferencesFragmentDoc = gql`
  fragment VisitorSiteNotificationPreferences on VisitorSiteNotificationPreferences {
    __typename
    site {
      ...Site
    }
    deliveryCheckIn
    deliveryCheckInIsEditable
    deliveryHighRisk
    deliveryHighRiskIsEditable
    visitHighRisk
    visitHighRiskIsEditable
    visitLowRisk
    visitLowRiskIsEditable
    visitSignIn
    visitSignInIsEditable
    visitorEodSummary
    visitorEodSummaryIsEditable
  }
  ${SiteFragmentDoc}
`;
