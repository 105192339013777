import dayjs, { Dayjs } from 'dayjs';
import { OrgFeatureFlag } from 'xo/constants';
import { ApiModule } from 'xo/graphql/api/enums/module.generated';
import { OrganisationReadModel } from 'xo/rest-api';

const SOFT_DELETE_CUT_OFF_DAYS = 6;

const endOfUtcQuarter = (date: Dayjs) => {
  const dateUtc = date.utc();
  const quarter = Math.ceil((dateUtc.month() + 1) / 3);
  const firstMonthOfNextQuarter = quarter * 3;
  return dateUtc.month(firstMonthOfNextQuarter).startOf('month');
};

// soft deletions after the end of a quarter can affect board reporting, so we try to limit this
export const softDeleteCutOff = (entityDate: Dayjs) =>
  endOfUtcQuarter(entityDate).add(SOFT_DELETE_CUT_OFF_DAYS, 'days');

export const nowAfterSoftDeleteCutOff = (entityDate: undefined | Dayjs) =>
  !entityDate || dayjs().isAfter(softDeleteCutOff(entityDate));

export const organisationPrebookingEnabled = (
  organisation?: Pick<OrganisationReadModel, 'modules' | 'features'>,
) =>
  !!organisation?.modules.includes(ApiModule.Visitor) &&
  !!organisation?.features.includes(OrgFeatureFlag.VisitorPrebooking);
