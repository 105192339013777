import { useIdle } from '@mantine/hooks';
import { pick } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { ApiCurrentUserFragment } from 'xo/graphql/api/current-user-fragment.generated';
import { ApiInternalVisitorStatus } from 'xo/graphql/api/enums/internal-visitor-status.generated';
import { useOptionalCurrentUser } from 'xo/login/current-user-hooks';
import {
  appRoutes,
  visitorAppRoot,
  visitorRoutes,
} from 'xo/navigation/web-routes';
import { SiteReadModel } from 'xo/rest-api';
import { VisitModel } from '../../models/visitor-log-models';
import { LocalStorage } from '../shared/local-storage';
import {
  internalVisitorStatusAtOrg,
  isInternalVisitor,
} from '../visits/visit-utils';

// If the data format of the LocalStorageVisitor changes in a backwards-incompatible way,
// increment this version
const visitorLocalStorageVersion = '3';
export const visitorLocalStorageKey = LocalStorage.key(
  'visitor',
  visitorLocalStorageVersion,
);
export type LocalStorageVisit =
  | Pick<
      VisitModel,
      | 'entryDateTime'
      | 'exitDateTime'
      | 'host'
      | 'purpose'
      | 'otherPurpose'
      | 'scheduledStartDatetime'
      | 'scheduledEndDatetime'
    >
  | undefined;

export interface LocalStorageVisitor {
  id: string;
  name: string;
  phone?: string;
  email?: string;
  company?: string;
  internalVisitorStatusAtOrganisation?: ApiInternalVisitorStatus;
  internalVisitorStatusOrganisationId?: string;
  agreedTerms: boolean;
  durationInMins?: number;
  purpose?: string;
  // old style visitor internal status tracking, preserved to be able to upgrade old local-storage visitors
  internalVisitorStatus?: ApiInternalVisitorStatus;
  visitorIsInternal?: boolean;
  // storing the visit on the visitor key to avoid having to re-implement when to get and set the visit.
  visit?: LocalStorageVisit;
}

export type LocalStorageVisitorForOrg = LocalStorageVisitor & {
  internalVisitorStatusAtVisitOrg?: ApiInternalVisitorStatus;
};

export const removeLocalStorageVisitor = () =>
  LocalStorage.removeItem(visitorLocalStorageKey);

export const persistLocalStorageVisitor = ({
  visit,
  ...user
}: LocalStorageVisitor) => {
  const localStorageVisitor = LocalStorage.getItem(visitorLocalStorageKey) as
    | LocalStorageVisitor
    | undefined;

  // only save visit info we need, making sure we never overwrite an existing visit with undefined
  const visitorVisit: LocalStorageVisit = visit
    ? pick(visit ?? localStorageVisitor?.visit, [
        'entryDateTime',
        'exitDateTime',
        'host',
        'purpose',
        'otherPurpose',
        'scheduledStartDatetime',
        'scheduledEndDatetime',
      ])
    : undefined;

  const visitor: LocalStorageVisitor = {
    id: user.id,
    name: user.name,
    phone: user.phone,
    email: user.email,
    agreedTerms: user.agreedTerms,
    company: user.company,
    internalVisitorStatusAtOrganisation:
      user.internalVisitorStatusAtOrganisation,
    internalVisitorStatusOrganisationId:
      user.internalVisitorStatusOrganisationId,
    durationInMins: user.durationInMins,
    purpose: user.purpose,
    visit: visitorVisit,
  };

  LocalStorage.setItem(visitorLocalStorageKey, visitor);
};

export const usePersistVisitorLocalStorage = (
  visitor?: LocalStorageVisitor,
) => {
  const kioskMode = useIsKioskMode();
  useEffect(() => {
    if (visitor && visitor.id && !kioskMode) {
      persistLocalStorageVisitor(visitor);
    }
  }, [visitor, kioskMode]);
};

// callback version of usePersistVisitorLocalStorage to allow using params from a parent function
export const usePersistVisitorLocalStorageCb = () => {
  const kioskMode = useIsKioskMode();

  return useCallback(
    (visitor?: LocalStorageVisitor) => {
      if (visitor && visitor.id && !kioskMode) {
        persistLocalStorageVisitor(visitor);
      }
    },
    [kioskMode],
  );
};

// FIXME Simplify company field storage
export const useGetVisitorLocalStorage = ({
  redirectTo,
  organisationId,
  organisationName,
}: {
  organisationId: string | undefined;
  organisationName: string | undefined;
  redirectTo?: string;
}): LocalStorageVisitorForOrg | undefined => {
  const kioskMode = useIsKioskMode();

  const visitor = useMemo(
    () =>
      LocalStorage.getItem(visitorLocalStorageKey) as
        | LocalStorageVisitor
        | undefined,
    [],
  );

  const history = useHistory();
  if (visitor && kioskMode) {
    removeLocalStorageVisitor();
    return;
  }

  if (!visitor && redirectTo) {
    history.push(redirectTo);
  }

  const internalVisitorStatusAtVisitOrg = (() => {
    if (!visitor) {
      return undefined;
    }

    if (!!visitor.internalVisitorStatus) {
      // old-style tracking, which we need to migrate to new-style that also tracks the organisation ID
      if (
        visitor.internalVisitorStatus ===
          ApiInternalVisitorStatus.InternalConfirmed ||
        visitor.internalVisitorStatus ===
          ApiInternalVisitorStatus.InternalSelfReported
      ) {
        // old-style internal, let's make a guess based on 'company': it would likely have been
        // automatically set when they indicated they were an employee, and set to the organisation name.
        // If it doesn't match exactly, fall back to 'clearing' out the visitor and asking them to reauth
        return organisationName && visitor.company === organisationName
          ? visitor.internalVisitorStatus
          : undefined;
      } else {
        return ApiInternalVisitorStatus.External;
      }
    }

    // new style tracking
    if (organisationId) {
      // we know the organisation, so we can accurately determine this
      return internalVisitorStatusAtOrg({
        visitor,
        organisationId: organisationId,
      });
    }

    // if we don't (yet) know the organisation, default to external
    return ApiInternalVisitorStatus.External;
  })();

  return (
    visitor &&
    internalVisitorStatusAtVisitOrg && {
      ...visitor,
      internalVisitorStatusAtVisitOrg,
      // ensure we clear out the old style status tracking
      internalVisitorStatus: undefined,
      visitorIsInternal: isInternalVisitor(internalVisitorStatusAtVisitOrg),
    }
  );
};

export const useIsKioskMode = () => !!useRouteMatch(visitorRoutes.kioskRoot());

export const useIdleTimeout = (timeout: number, onIdle: () => void) => {
  const isIdle = useIdle(timeout, {
    initialState: false,
    events: ['input', 'keypress', 'mousemove', 'touchmove', 'click', 'scroll'],
  });

  useEffect(() => {
    if (isIdle) {
      onIdle();
    }
  }, [isIdle, onIdle]);
};

export const useKioskSetup = (
  siteShortCode: string | undefined,
  site: SiteReadModel | undefined,
  timeoutMs: number = 60 * 1000,
) => {
  const user = useOptionalCurrentUser();
  const kioskMode = useIsKioskMode();
  const history = useHistory();

  // If user has been inactive for 1 min and we're in kiosk mode, redirect to the main page
  useIdleTimeout(timeoutMs, () => {
    if (
      siteShortCode &&
      kioskMode &&
      window.location.pathname !==
        `${visitorRoutes.kioskRoot(siteShortCode)}/site`
    ) {
      window.location.pathname = visitorRoutes.kioskRoot(siteShortCode);
    }
  });

  // Only allow kiosk usage when logged in as kiosk user, but only when kiosk mode is active since this hook can be called in other modes
  useEffect(() => {
    if (kioskMode && site && !user?.kioskUserAtSite?.id) {
      history.push(appRoutes.account.login);
    }
  });

  // On mount, ensure we clear out any visitors in local storage
  const rememberedVisitor = useGetVisitorLocalStorage({
    organisationId: undefined,
    organisationName: undefined,
  });
  useEffect(() => {
    if (rememberedVisitor && kioskMode) {
      removeLocalStorageVisitor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

// FIXME Add a test
export const useKioskRedirect = (
  actualCurrentUser: ApiCurrentUserFragment | null,
) => {
  const history = useHistory();
  const kioskMatch = useRouteMatch(visitorRoutes.kioskRoot());
  const loginMatch = useRouteMatch(appRoutes.account.login);

  useEffect(() => {
    if (actualCurrentUser?.kioskUserAtSite && !(kioskMatch || loginMatch)) {
      history.replace(
        visitorRoutes.kioskRoot(actualCurrentUser.kioskUserAtSite.shortCode),
      );
    }
  }, [actualCurrentUser, history, kioskMatch, loginMatch]);
};

export type AppPeopleCheckInParams = {
  orgShortCode?: string;
  siteShortCode?: string;
};

export const useAppPeopleCheckInParams = () =>
  useParams<AppPeopleCheckInParams>();

export const useBackToMainPage = ({
  orgShortCode,
  siteShortCode,
}: AppPeopleCheckInParams) => {
  const kioskMode = useIsKioskMode();
  const history = useHistory();

  return () => {
    const path = visitorAppRoot({ orgShortCode, siteShortCode, kioskMode });
    history.push(path);
  };
};

export const useOptionalBackToMainPage = ({
  orgShortCode,
  siteShortCode,
}: AppPeopleCheckInParams) => {
  const onBackToMainPage = useBackToMainPage({ orgShortCode, siteShortCode });

  return !orgShortCode && !siteShortCode ? undefined : onBackToMainPage;
};
