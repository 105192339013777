import qs from 'querystring';
import { ApiSupportedPhoneRegion } from 'xo/graphql/api/enums/supported-phone-region.generated';
import { PhoneSupport } from 'xo/rest-api';
import { phoneInfoForPhoneRegion } from './constants';
import logger from './logger';

export const EMAIL_REGEX = /^.+@.+\..+$/;
export const isValidEmail = (value: string) => EMAIL_REGEX.test(value);

export interface IsValidPhoneNumberParams {
  phone: string | undefined;
  phoneRegion: ApiSupportedPhoneRegion;
  onError: (error: string) => void;
  origin?: string;
}

export interface IsValidPhoneNumber {
  valid: boolean;
  supportsSms?: PhoneSupport;
  supportsWhatsapp?: PhoneSupport;
  e164?: string;
}

export const isValidPhoneNumber = async ({
  phone,
  phoneRegion,
  origin,
  onError,
}: IsValidPhoneNumberParams): Promise<IsValidPhoneNumber> => {
  try {
    // early out if the string isn't the right length
    if (
      !phone ||
      phone.replace(/ /g, '').length <
        phoneInfoForPhoneRegion[phoneRegion].minLength
    ) {
      return { valid: false };
    }

    // FIXME Switch to GraphQL when phone validation is available there
    const response = await fetch(
      `${origin ?? ''}/api/v3-open/validation/phone?${qs.stringify({
        phone,
        country: phoneRegion,
      })}`,
    );

    const data = await response.json();

    return {
      valid: data.valid,
      supportsSms: data.supportsSms,
      supportsWhatsapp: data.supportsWhatsapp,
      e164: data.e164,
    };
  } catch (e) {
    logger.error(e);
    onError(`Sorry, a server error prevented phone validation`);
    return { valid: false };
  }
};
