import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useRef, useState } from 'react';
import { ApiProvider } from 'xo/graphql/api/enums/provider.generated';
import { ApiPositionFragment } from 'xo/graphql/api/position-fragment.generated';
import { ApiStreetAddressFragment } from 'xo/graphql/api/street-address-fragment.generated';
import { SvgLocation } from 'xo/svg/svg-location';
import { env } from '../env';
import { Input } from '../forms/input';
import './address-autocomplete.overrides.css';

const googleApiKey = env.REACT_APP_GOOGLE_API_KEY!;

// this is slightly different to the one used for backfilling in the backend, so we can distinguish them
const FIXED_PRECISE_LOCATION_UNCERTAINTY = 50.0987654321;

export interface AddressAutoCompleteProps {
  className?: string;
  onSelect: (props: {
    address?: ApiStreetAddressFragment;
    position?: ApiPositionFragment;
  }) => void;
  disabled?: boolean;
  type?: 'suburb' | 'address';
}

const PHILIPPINES: string = 'PH';
const USA: string = 'US';

const combine = (
  v1: string | undefined,
  sep: string,
  v2: string | undefined,
) => {
  const joined = [v1, v2].filter(Boolean).join(sep);
  // normalise empty strings to undefined
  return joined ? joined : undefined;
};

const formatLine1 = ({
  countryCode,
  addressComponent,
}: {
  countryCode: string | undefined;
  addressComponent: (type: string) => string | undefined;
}) => {
  const unitNumber = addressComponent('subpremise');
  const streetNumber = addressComponent('street_number');
  const street = addressComponent('route');

  switch (countryCode) {
    case PHILIPPINES:
      // The Philippines includes the barangay (which seems to come through as the sublocality) in their first line
      // e.g. 12/34 Main St, Barangay 56
      return combine(
        combine(combine(unitNumber, '/', streetNumber), ' ', street),
        ', ',
        addressComponent('sublocality'),
      );

    case USA:
      // Sometimes unit numbers are just numbers, which seems to be formatted with a # (e.g. in
      // Google Maps or USPS Postal Addressing Standards Appendix C2
      // https://pe.usps.com/text/pub28/pub28apc_003.htm)
      const adjustedUnitNumber =
        unitNumber && /^\d+$/.test(unitNumber) ? `#${unitNumber}` : unitNumber;

      // See USPS "Postal Addressing Standards", e.g. https://pe.usps.com/text/pub28/pub28c2_012.htm
      // e.g. 12 Main St, Floor 34, or 12 Main St, #34
      return combine(
        combine(streetNumber, ' ', street),
        ', ',
        adjustedUnitNumber,
      );

    default:
      // Australian-style addresses by default
      // e.g. 12/45 Example St
      return combine(combine(unitNumber, '/', streetNumber), ' ', street);
  }
};

export const autoCompleteToApiAddressTransformer = (
  result: google.maps.places.PlaceResult | undefined,
): ApiStreetAddressFragment | undefined => {
  if (!result?.address_components) return undefined;

  const addressComponent = (
    type: string,
    property: Exclude<
      keyof google.maps.GeocoderAddressComponent,
      'types'
    > = 'long_name',
  ) => {
    const component = result?.address_components?.find(c =>
      c.types.includes(type),
    );
    return component ? component[property] : undefined;
  };

  const countryCode = addressComponent('country', 'short_name');

  return {
    line1: formatLine1({ countryCode, addressComponent }),
    line2: undefined,
    suburb: addressComponent('locality'),
    postcode: addressComponent('postal_code'),
    state: addressComponent('administrative_area_level_1', 'short_name'),
    countryCode,
  };
};

export const AddressAutoComplete = ({
  className,
  disabled,
  onSelect,
  type,
}: AddressAutoCompleteProps) => {
  const [googleLib, setGoogleLib] = useState<typeof google.maps.places>();
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    new Loader({
      apiKey: googleApiKey,
      version: 'weekly',
    })
      .importLibrary('places')
      .then((googleLib: typeof google.maps.places) => setGoogleLib(googleLib))
      .catch(console.error);
  }, []);

  const onCleanUp = () => {
    autoCompleteRef.current?.unbindAll();
    document.querySelectorAll('.pac-container')?.forEach(e => e.remove());
  };

  useEffect(() => onCleanUp, []);

  useEffect(() => {
    if (inputRef.current && googleLib) {
      const options: google.maps.places.AutocompleteOptions = {
        // If you change this list of countries:
        // - Google documents a maximum of 5 countries limit
        // - updating the CountryCode enum (in address-utils.ts) and associated handling
        componentRestrictions: { country: ['au', 'fj', 'ph', 'nz', 'us'] },
        fields: ['address_components', 'geometry'],
        types: type === 'suburb' ? ['postal_code', 'locality'] : ['address'],
      };

      autoCompleteRef.current = new googleLib.Autocomplete(
        inputRef.current,
        options,
      );

      autoCompleteRef.current.addListener('place_changed', () => {
        const result = autoCompleteRef.current?.getPlace();

        const position: ApiPositionFragment | undefined = result?.geometry
          ?.location
          ? {
              lat: result?.geometry?.location?.lat(),
              lng: result?.geometry?.location?.lng(),
              provider: ApiProvider.GoogleMaps,
              // locations derived from this autocomplete will generally be fairly precise (e.g. it
              // doesn't autocomplete "Sydney" to the whole city, and similarly for states etc.). It
              // does autocomplete whole roads, i.e. no street number, but determining the details are hard.
              //
              // FIXME: do some a more accurate estimate of uncertainty based on the returned
              // geometry (see geocode.py for inspiration)
              accLatlng: FIXED_PRECISE_LOCATION_UNCERTAINTY,
            }
          : undefined;

        const address = autoCompleteToApiAddressTransformer(result);

        onSelect({ address, position });
      });
    }

    return onCleanUp;
  }, [inputRef, googleLib, onSelect, type]);

  const placeholder = `Enter ${
    type === 'suburb' ? 'suburb or postcode' : 'address'
  }`;

  return (
    <div className={className}>
      <Input
        prefix={<SvgLocation />}
        disabled={!googleLib || disabled}
        placeholder={!googleLib ? 'Loading...' : placeholder}
        inputRef={r => {
          inputRef.current = r;
        }}
      />
    </div>
  );
};
