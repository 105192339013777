import classNames from 'classnames';
import React, { useContext, useMemo, useState } from 'react';
import { toId } from 'xo/utils/string-utils';
import { isRunningInWebView } from 'xo/webview/webview-events';
import { useIsKioskMode } from '../people-check-in/check-in-hooks';
import {
  CheckInNavBar,
  CheckInNavBarProps,
} from '../people-check-in/check-in-navbar';
import { SystemConfigContext } from '../shared/system-config-provider';
import { Container } from './container';
import { Footer, FooterProps } from './footer';
import { FooterButtonsProps } from './footer-buttons';
import HostPortalNavBar, { NavBarProps } from './host-portal-navbar';
import { KioskNavBar } from './kiosk-navbar';
import { PageTitle } from './page-title';

export type PageConfigPageTitle = {
  text: string;
  color?: 'black' | 'primary' | 'dark';
  titleTagOnly?: boolean;
  className?: string;
};

type HostPortalNavBarVariant = Omit<NavBarProps, 'onSignOut'> & {
  variant?: 'host-portal';
};
type KioskNavBarVariant = Omit<NavBarProps, 'onSignOut'> & { variant: 'kiosk' };
type CheckInNavBarVariant = CheckInNavBarProps & { variant: 'check-in' };

export type NavBarVariantProps =
  | HostPortalNavBarVariant
  | KioskNavBarVariant
  | CheckInNavBarVariant;

export interface PageConfig {
  navbar?: NavBarVariantProps;
  pageTitle?: PageConfigPageTitle;
  footer?: FooterProps & FooterButtonsProps;
  noFooter?: boolean;
  noPadding?: boolean;
}

export interface PageContextProps {
  onPageConfig: (config: PageConfig) => void;
  page: PageConfig;
}

export const PageContext = React.createContext<PageContextProps>(null as any);

export const PageProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [page, onPageConfig] = useState<PageConfig>({});

  const { navbar: navbarProps, footer, pageTitle, noFooter, noPadding } = page;

  // FIXME Better way of identifying unauthed?
  const kioskMode = useIsKioskMode();

  const systemConfig = useContext(SystemConfigContext);

  const navbar = useMemo(() => {
    if (navbarProps) {
      switch (navbarProps.variant) {
        case 'kiosk':
          return (
            <KioskNavBar
              {...navbarProps}
              predictedReleaseTime={
                systemConfig?.predictedNextReleaseTimestampTz
              }
            />
          );
        case 'check-in':
          // Only showing the check-in navbar in webview for now.
          // (see discussion in https://exoflare.atlassian.net/browse/KX-1580)
          return isRunningInWebView() ? (
            <CheckInNavBar {...navbarProps} />
          ) : null;
        case 'host-portal':
          return (
            <HostPortalNavBar
              {...navbarProps}
              predictedReleaseTime={
                systemConfig?.predictedNextReleaseTimestampTz
              }
            />
          );
        default:
          // TODO: Remove in favor of explicit variants
          if (kioskMode) {
            return (
              <KioskNavBar
                {...navbarProps}
                predictedReleaseTime={
                  systemConfig?.predictedNextReleaseTimestampTz
                }
              />
            );
          }
          return (
            <HostPortalNavBar
              {...navbarProps}
              predictedReleaseTime={
                systemConfig?.predictedNextReleaseTimestampTz
              }
            />
          );
      }
    }
    return null;
  }, [navbarProps, systemConfig, kioskMode]);

  return (
    <PageContext.Provider value={{ onPageConfig, page }}>
      <div id="page-provider">
        <PageTitle title={pageTitle?.text ?? navbarProps?.title} />

        {navbar}

        {pageTitle && !pageTitle.titleTagOnly ? (
          <Container>
            <h1
              id={toId(pageTitle?.text)}
              className={classNames(
                'mt-8 text-3xl font-medium leading-7 -tracking-1px',
                pageTitle.className,
                {
                  'text-blue-400':
                    pageTitle.color === 'primary' || !pageTitle.color,
                  'text-blue-600': pageTitle.color === 'dark',
                  'text-black': pageTitle.color === 'black',
                  'mt-4': navbarProps && kioskMode,
                },
              )}
            >
              {pageTitle.text}
            </h1>
          </Container>
        ) : null}

        <div
          className={classNames('overflow-x-hidden pb-32', {
            'pt-4': navbarProps && !noPadding,
          })}
          data-e2e-main-content
        >
          {children}
        </div>

        {footer && !noFooter ? <Footer {...footer} /> : undefined}
      </div>
    </PageContext.Provider>
  );
};
