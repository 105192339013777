import { useContext } from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { GetCurrentUserDocument } from 'xo/graphql/api/get-current-user-query.generated';
import { useClient } from 'xo/graphql/urql-provider';
import {
  DetailedSiteReadModel,
  SiteGetResponse,
  SiteOpenGetResponse,
  SiteReadModel,
} from 'xo/rest-api';
import { ErrorPageContext } from '../app/components/error-page-provider';
import { getApi } from './axios-instance';

const siteApi = getApi('site');
export const siteQueryKeys = {
  adminSites: 'adminSites',
  site: (id: string) => `site:${id}`,
};

export const useGetSite = ({ id }: { id?: string }) => {
  const { onNotFoundAdminAutoAssume } = useContext(ErrorPageContext);
  return useQuery<DetailedSiteReadModel>(
    siteQueryKeys.site(id ?? ''),
    () =>
      getApi('site', {
        notifyOnError: false,
        onErrorPage: onNotFoundAdminAutoAssume({ type: 'site', id: id! }),
      })
        .get<SiteGetResponse>(`/${id}`)
        .then(response => response.data.site),
    { enabled: !!id },
  );
};

export const useGetSiteOpen = (
  { shortCode }: { shortCode?: string },
  options?: UseQueryOptions<SiteReadModel, unknown, SiteReadModel>,
) => {
  return useQuery<SiteReadModel>(
    siteQueryKeys.site(shortCode ?? ''),
    () =>
      getApi('site', {
        notifyOnError: false,
        open: true,
      })
        .get<SiteOpenGetResponse>(`/${shortCode}`)
        .then(response => response.data.site),
    { ...options, enabled: !!shortCode },
  );
};

export const useUpdateQueryClientSite = () => {
  const client = useQueryClient();
  const gqlClient = useClient();

  return (site: Partial<DetailedSiteReadModel> & { id: string }) => {
    const cacheSite =
      client.getQueryData<DetailedSiteReadModel>(siteQueryKeys.site(site.id)) ??
      [siteQueryKeys.adminSites]
        .flatMap(key => client.getQueryData<DetailedSiteReadModel[]>(key))
        ?.find(s => s?.id === site.id);

    if (cacheSite) {
      const newSite: DetailedSiteReadModel = {
        ...cacheSite,
        ...site,
      };

      gqlClient.query(GetCurrentUserDocument, {});

      client.setQueryData(siteQueryKeys.site(cacheSite.id), newSite);
      client.setQueryData(siteQueryKeys.site(cacheSite.shortCode), newSite);
      [siteQueryKeys.adminSites].forEach(key =>
        client.setQueryData<DetailedSiteReadModel[]>(
          key,
          sites => sites?.map(s => (s.id === newSite.id ? newSite : s)) ?? [],
        ),
      );
    }
  };
};

export const useDeleteSite = () => {
  const client = useQueryClient();
  return useMutation(
    (request: { id: string }) =>
      siteApi.delete<SiteGetResponse>(`/${request.id}`).then(response => {
        const site = response.data.site;
        client.setQueryData(siteQueryKeys.site(site.id), site);
        client.setQueryData(siteQueryKeys.site(site.shortCode), site);
        return site;
      }),
    { retry: false },
  );
};
