import { useHistory } from 'react-router-dom';
import { IconButton } from 'xo/components/icon-button';
import Menu from 'xo/components/menu';
import { Box } from 'xo/core';
import { appRoutes } from 'xo/navigation/web-routes';
import { SvgAppLogo } from 'xo/svg/svg-app-logo';
import { SvgCross } from 'xo/svg/svg-cross';
import { Container } from '../components/container';
import { FixedHeader } from '../components/fixed-header';
import { useIsKioskMode } from './check-in-hooks';
import { CheckInNavBarContent } from './check-in-navbar-content';

export interface CheckInNavBarProps {
  title?: string;
  exitFlowTo?: string;
}

export const CheckInNavBar = ({
  exitFlowTo,
  title = 'ExoFlare',
  ...props
}: CheckInNavBarProps) => {
  const history = useHistory();

  const kioskMode = useIsKioskMode();

  const icon = exitFlowTo ? (
    <IconButton
      icon={<SvgCross />}
      backgroundColor="grey.100"
      onPress={() => history.push(exitFlowTo)}
    />
  ) : (
    <Menu
      trigger={
        <IconButton
          icon={<SvgAppLogo />}
          accessibilityLabel="Navigation menu"
        />
      }
      items={[
        {
          key: 'host-portal',
          label: 'Host portal',
          onPress: () => history.push(appRoutes.root),
        },
      ]}
    />
  );

  // Not showing the navbar in kiosk mode yet
  if (kioskMode) return null;

  return (
    <FixedHeader borderBottomWidth={1} borderBottomColor="grey.200">
      <Container>
        <Box w="full" h="full" justify="center">
          <CheckInNavBarContent {...props} icon={icon} title={title} />
        </Box>
      </Container>
    </FixedHeader>
  );
};
