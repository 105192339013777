import { useCallback, useEffect } from 'react';
import { getAuthToken } from 'xo/auth-utils';
import { shallow } from 'zustand/shallow';
import { AuthStatus, useUserStore } from './user-store';

export const useIsLoggedIn = () => {
  const [token, authStatus] = useUserStore(
    state => [state.token, state.authStatus],
    shallow,
  );
  return !!token && authStatus === AuthStatus.Authenticated;
};

export const useLoadAuthStatus = () => {
  const [setToken, authStatus, setAuthStatus] = useUserStore(
    state => [state.setToken, state.authStatus, state.setAuthStatus],
    shallow,
  );

  const onLoad = useCallback(async () => {
    if (authStatus === AuthStatus.Loading) {
      const authToken = await getAuthToken();

      setAuthStatus(
        authToken ? AuthStatus.Authenticated : AuthStatus.Unauthenticated,
      );
      setToken(authToken);
    }
  }, [authStatus, setAuthStatus, setToken]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return authStatus;
};
