// generated by constants-resolvers-plugin.js

export enum ApiResolver {
  addressBookContact = 'addressBookContact',
  addressBookContactsList = 'addressBookContactsList',
  addressBookSite = 'addressBookSite',
  addressBookSitesList = 'addressBookSitesList',
  admin = 'admin',
  batchUpsertConsignmentNote = 'batchUpsertConsignmentNote',
  consignmentNote = 'consignmentNote',
  consignmentNoteExportExcel = 'consignmentNoteExportExcel',
  consignmentNoteExportPdf = 'consignmentNoteExportPdf',
  consignmentNotes = 'consignmentNotes',
  createAddressBookContact = 'createAddressBookContact',
  createAddressBookSite = 'createAddressBookSite',
  createOutbreak = 'createOutbreak',
  createRoadVehicle = 'createRoadVehicle',
  createScheduledMovement = 'createScheduledMovement',
  createSite = 'createSite',
  createSiteMovementMatrix = 'createSiteMovementMatrix',
  createUser = 'createUser',
  entries = 'entries',
  entriesExportExcel = 'entriesExportExcel',
  fileExport = 'fileExport',
  formUpdateHeartbeat = 'formUpdateHeartbeat',
  ingestScheduledMovementSpreadsheet = 'ingestScheduledMovementSpreadsheet',
  me = 'me',
  organisation = 'organisation',
  outbreak = 'outbreak',
  outbreaksList = 'outbreaksList',
  reserveConsignmentNoteIds = 'reserveConsignmentNoteIds',
  scheduledMovement = 'scheduledMovement',
  scheduledMovementSummary = 'scheduledMovementSummary',
  scheduledMovements = 'scheduledMovements',
  scheduledMovementsExportExcel = 'scheduledMovementsExportExcel',
  sendTestNotification = 'sendTestNotification',
  site = 'site',
  siteByShortCode = 'siteByShortCode',
  siteMovementMatrix = 'siteMovementMatrix',
  siteSign = 'siteSign',
  sites = 'sites',
  system = 'system',
  trip = 'trip',
  tripExportExcel = 'tripExportExcel',
  trips = 'trips',
  tripsFilter = 'tripsFilter',
  updateAddressBookContact = 'updateAddressBookContact',
  updateAddressBookSite = 'updateAddressBookSite',
  updateGeofence = 'updateGeofence',
  updateOrganisation = 'updateOrganisation',
  updateOutbreak = 'updateOutbreak',
  updateRoadVehicle = 'updateRoadVehicle',
  updateScheduledMovement = 'updateScheduledMovement',
  updateSite = 'updateSite',
  updateSiteAdmin = 'updateSiteAdmin',
  updateSystemMobileAppConfig = 'updateSystemMobileAppConfig',
  updateSystemWebAppConfig = 'updateSystemWebAppConfig',
  updateTripEvent = 'updateTripEvent',
  updateUser = 'updateUser',
  updateUserAdmin = 'updateUserAdmin',
  updateUserDevicePushToken = 'updateUserDevicePushToken',
  updateUserPreferences = 'updateUserPreferences',
  updateVehicleConfig = 'updateVehicleConfig',
  user = 'user',
  vehicle = 'vehicle',
  vehiclesList = 'vehiclesList',
  visits = 'visits',
}
