import { Menu as AntMenu, Dropdown } from 'antd';
import { Box, Text } from 'xo/core';
import { colors } from 'xo/styles/tailwind-theme';
import { HelpTooltip } from './help-tooltip';
import { MenuProps } from './menu.props';

const Menu = ({ trigger, items }: MenuProps) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <Dropdown
      overlay={
        <AntMenu style={{ padding: 0 }}>
          {items.map(item => (
            <AntMenu.Item
              onClick={item.onPress}
              disabled={item.disabled}
              role="button"
            >
              <Box
                py="1"
                direction="row"
                align="center"
                justify="space-between"
              >
                <Text
                  color={
                    item.disabled
                      ? 'grey.400'
                      : item.variant === 'warning'
                        ? 'red.500'
                        : 'brandGrey.600'
                  }
                >
                  {item.label}
                </Text>
                {item.tooltip && (
                  <HelpTooltip iconColor={colors.blue[600]}>
                    {item.tooltip}
                  </HelpTooltip>
                )}
              </Box>
            </AntMenu.Item>
          ))}
        </AntMenu>
      }
      trigger={['click']}
    >
      {trigger}
    </Dropdown>
  );
};

export default Menu;
