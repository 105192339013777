// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
export type ApiNotificationMethodsFragment = {
  __typename?: 'NotificationMethods';
  sms: boolean;
  email: boolean;
  devicePush: boolean;
  whatsapp: boolean;
};

export const NotificationMethodsFragmentDoc = gql`
  fragment NotificationMethods on NotificationMethods {
    __typename
    sms
    email
    devicePush
    whatsapp
  }
`;
