import { ApiTransportUserKind } from 'xo/graphql/api/enums/transport-user-kind.generated';
import { ApiUserKind } from 'xo/graphql/api/enums/user-kind.generated';
import { ApiUserStatus } from 'xo/graphql/api/enums/user-status.generated';

import { Dayjs } from 'dayjs';
import gql from 'graphql-tag';
import {
  ApiOrganisationFragment,
  OrganisationFragmentDoc,
} from './organisation-fragment.generated';
import {
  ApiSiteBriefSummaryFragment,
  SiteBriefSummaryFragmentDoc,
} from './site-brief-summary-fragment.generated';
import { ApiSiteFragment, SiteFragmentDoc } from './site-fragment.generated';
import {
  ApiUserDevicePushTokenFragment,
  UserDevicePushTokenFragmentDoc,
} from './user-device-push-token-fragment.generated';
import {
  ApiUserPreferencesFragment,
  UserPreferencesFragmentDoc,
} from './user-preferences-fragment.generated';
import {
  ApiUserRoleFragment,
  UserRoleFragmentDoc,
} from './user-role-fragment.generated';
export type ApiCurrentUserFragment = {
  __typename?: 'CurrentUser';
  id: string;
  kind: ApiUserKind;
  name: string;
  email?: string;
  phone?: string;
  preferredPhone?: string;
  timezone?: string;
  createdAt?: Dayjs;
  transportKinds: Array<ApiTransportUserKind>;
  airtableDriverId?: string;
  features: Array<string>;
  status: ApiUserStatus;
  agreedTerms: boolean;
  hostAtSites: Array<{ __typename?: 'Site' } & ApiSiteFragment>;
  transportAtSites: Array<{ __typename?: 'Site' } & ApiSiteFragment>;
  organisation?: { __typename?: 'Organisation' } & ApiOrganisationFragment;
  preferences: { __typename?: 'UserPreferences' } & ApiUserPreferencesFragment;
  roles: Array<{ __typename?: 'UserRole' } & ApiUserRoleFragment>;
  devicePushTokens: Array<
    { __typename?: 'UserDevicePushToken' } & ApiUserDevicePushTokenFragment
  >;
  kioskUserAtSite?: { __typename?: 'Site' } & ApiSiteBriefSummaryFragment;
};

export const CurrentUserFragmentDoc = gql`
  fragment CurrentUser on CurrentUser {
    __typename
    id
    kind
    name
    email
    phone
    preferredPhone
    timezone
    hostAtSites {
      ...Site
    }
    transportAtSites {
      ...Site
    }
    organisation {
      ...Organisation
    }
    createdAt
    transportKinds
    preferences {
      ...UserPreferences
    }
    roles {
      ...UserRole
    }
    airtableDriverId
    features
    devicePushTokens {
      ...UserDevicePushToken
    }
    status
    agreedTerms
    kioskUserAtSite {
      ...SiteBriefSummary
    }
  }
  ${SiteFragmentDoc}
  ${OrganisationFragmentDoc}
  ${UserPreferencesFragmentDoc}
  ${UserRoleFragmentDoc}
  ${UserDevicePushTokenFragmentDoc}
  ${SiteBriefSummaryFragmentDoc}
`;
