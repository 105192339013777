import { Path } from 'react-native-svg';
import { colors } from 'xo/styles/restyle/colors';
import { SvgIcon } from './svg-icon';

export const SvgAppLogo = () => (
  <SvgIcon width={30} height={30} viewBox="0 0 31 31" fill="none">
    <Path
      d="M15.8333 30.8333C7.36273 30.8333 0.833328 24.1274 0.833328 16.0098C0.833328 7.71567 7.36273 0.833313 15.8333 0.833313C24.3039 0.833313 30.8333 7.71567 30.8333 16.0098C30.8333 24.1274 24.3039 30.8333 15.8333 30.8333Z"
      fill={colors['brandOrange.500']}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.9109 24.8456C26.2115 27.1253 23.86 28.9072 21.0961 29.915H15.8333V1.75171H21.032C23.7802 2.76489 26.1243 4.56109 27.8304 6.86133H21.5065V13.1377H29.0708V18.1669H21.5065V24.8456H27.9109Z"
      fill="black"
    />
  </SvgIcon>
);
